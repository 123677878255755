import {
  Box,
  Container,
  Divider,
  IconButton,
  Link,
  Typography,
} from "@material-ui/core";
import { Facebook, Instagram, LinkedIn, YouTube } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import Logo from "../assets/internse.png";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#f5f5f573",
  },
  wrap: {
    gap: "4rem",
    [theme.breakpoints.down("sm")]: {
      gap: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      gap: "1rem",
    },
  },
  logo: {
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "40%",
    },
  },
}));
const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <Box mt={"5rem"} bgcolor={"#021742"} padding="3rem 0 1rem 0" color="#fff">
        <Container>
          <Box textAlign={"center"}>
            <img
              src={Logo}
              className={classes.logo}
              alt="footer-logo"
              width="20%"
            />
            <Typography>
              Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt.
              consectetur adipiscing elit,
            </Typography>
          </Box>

          <Box
            display={"flex"}
            className={classes.wrap}
            justifyContent="center"
            margin="2rem 0"
          >
            <Link color="secondary" underline="none" href="/">
              <Typography>Home</Typography>
            </Link>
            <Link color="secondary" underline="none" href="/joblists">
              <Typography>Find A Job</Typography>
            </Link>
            <Link color="secondary" underline="none" href="/pricing">
              <Typography>Pricing</Typography>
            </Link>
            <Link color="secondary" underline="none" href="/">
              <Typography>About</Typography>
            </Link>
            <Link color="secondary" underline="none" href="/">
              <Typography>FAQs</Typography>
            </Link>
          </Box>
          <Divider
            light
            style={{
              background: "#ffffff4f",
              margin: "1rem auto",
              width: "80%",
            }}
          />
          <Box
            display={"flex"}
            className={classes.wrap}
            justifyContent="center"
          >
            <IconButton>
              <Facebook className={classes.icon} />
            </IconButton>
            <IconButton>
              <Instagram className={classes.icon} />
            </IconButton>
            <IconButton>
              <LinkedIn className={classes.icon} />
            </IconButton>
            <IconButton>
              <YouTube className={classes.icon} />
            </IconButton>
          </Box>
        </Container>
      </Box>
      <Box padding={"1rem 0"} bgcolor="#000" color="#ffffff50">
        <Container>
          <Typography variant="body1" style={{ textAlign: "center" }}>
            Copyright © {new Date(Date.now()).getFullYear()} Internse. All
            rights reserved.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Footer;

import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Profile from "./Profile";
import Application from "./Application";
import Settings from "./Settings";
import { connect } from "react-redux";
import Favourites from "./Favourites";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "whitesmoke",
    paddingBottom: "8rem",
  },
}));

const Account = (props) => {
  const classes = useStyles();
  const path = useLocation().pathname;

  const renderPage = () => {
    if (props.currentUser) {
      return (
        <>
          {path === "/account/profile" && <Profile />}
          {path === "/account/applications" && <Application />}
          {path === "/account/saved-jobs" && <Favourites />}
          {path === "/account/settings" && <Settings />}
        </>
      );
    }

    return <Navigate to="/" />;
  };

  return <div className={classes.root}>{renderPage()}</div>;
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {})(Account);

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Hidden,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  CheckCircle,
  Favorite,
  FavoriteBorder,
  Share,
  VerifiedUser,
  WatchLater,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Cover from "../assets/websites.jpg";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import {
  errorModal,
  successModal,
  loginUser,
  jobApplication,
  markFavourite,
  unmarkFavourite,
  jobViews,
} from "../actions/actions";
import SaveBox from "../components/SaveBox";
import User from "../assets/user.gif";
import PDF from "../assets/pdf.png";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "4rem",
    paddingBottom: "8rem",
    background: "whitesmoke",
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginBottom: ".5rem",
    border: "6px solid #fff",
    background: theme.secondaryColor,
  },
  coverImg: {
    position: "relative",
    height: "15rem",
    backgroundImage: `linear-gradient(45deg, rgba(0,0,0, 50%), transparent), url(${Cover})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "10px",
  },
  iconBtn: {
    border: `1px solid #fff`,
    borderRadius: "10px",
    padding: "8px",
    marginLeft: ".6rem",
    color: "#fff",
  },
  grid: {
    textAlign: "center",
  },
  gridItem: {
    padding: "1.5rem",
    border: `.5px solid ${grey[300]}`,
  },
  field: {
    marginBottom: "1.2rem",
  },
  flex: {
    display: "flex",
    gap: ".8rem",
    alignItems: "center",
  },
}));

const SingleJobList = (props) => {
  const { allJobs, jobViews } = props;
  const [job, setJob] = useState("");
  const classes = useStyles();
  const path = useLocation().pathname.split("/")[2];
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState({ open: false, text: "" });
  const [save, setSave] = useState(false);

  useEffect(() => {
    const fetchJob = async (val) => {
      if (allJobs) {
        const filteredJob = allJobs.filter((el) => el.slug === val)[0];
        const res = await jobViews(filteredJob._id);
        // console.log(res)
        setJob(res.data);
      }
    };

    fetchJob(path);
  }, [allJobs, path, jobViews]);

  const onFormSubmit = () => {
    if (email === "" || !email.includes("@")) {
      props.errorModal("Please provide a valid email");
      return;
    }
    if (password === "" || password.length < 8) {
      props.errorModal("Password must be more than 8 characters");
      return;
    }

    const data = { email, password, type: "Student" };
    setLoader({ open: true, text: "Logging in. Please wait..." });
    //make request
    setTimeout(async () => {
      const res = await props.loginUser(data);
      if (res.status === "success") {
        setLoader({ open: false, text: "" });
        setEmail("");
        setPassword("");
        props.successModal("Login successful");
      } else {
        setLoader({ open: false, text: "" });
        props.errorModal(res.message);
      }
    }, 1000);
  };

  const applyJob = () => {
    if (props.currentUser.total !== 100) {
      props.errorModal(
        "Invalid. Please complete your career profile (100%) to apply"
      );
      return;
    }

    //const job = allJobs.filter(el => el.slug === path)[0]

    setLoader({
      open: true,
      text: "Submitting your application. Please wait...",
    });
    const data = {
      company: job.company._id,
      job: job._id,
    };
    //console.log(data)
    setTimeout(async () => {
      const res = await props.jobApplication(data);
      if (res.status === "success") {
        setLoader({ open: false, text: "" });
        props.successModal("Your application has been submitted successfully");
      } else {
        setLoader({ open: false, text: "" });
        props.errorModal(
          "Sorry, could not submit application. Please try again"
        );
      }
    }, 5000);
  };

  const saveJob = () => {
    //const job = allJobs && allJobs.filter(el => el.slug === path)[0]
    const isFavourite =
      props.currentUser && props.currentUser.favourites.length > 0
        ? props.currentUser.favourites.filter((el) => el.job.id === job.id)[0]
        : null;
    if (isFavourite) {
      setSave(true);
      setTimeout(async () => {
        const res = await props.unmarkFavourite(job._id);
        if (res.status === "success") {
          setSave(false);
          props.successModal("Job has been umarked as Saved");
        } else {
          setSave(false);
          props.errorModal(res.message);
        }
      }, 1000);
    } else {
      setSave(true);
      setTimeout(async () => {
        const res = await props.markFavourite(job._id);
        if (res.status === "success") {
          setSave(false);
          props.successModal("Job has been marked as Saved");
        } else {
          setSave(false);
          props.errorModal(res.message);
        }
      }, 1000);
    }
  };

  const renderPage = () => {
    if (allJobs && allJobs.length > 0 && job) {
      //const job = allJobs.filter(el => el.slug === path)[0]
      const applied =
        props.currentUser && !props.currentUser.isCompany
          ? props.currentUser.applications.filter(
              (el) => el.job.id === job.id
            )[0]
          : null;
      const isFavourite =
        props.currentUser && !props.currentUser.isCompany
          ? props.currentUser.favourites.filter((el) => el.job.id === job.id)[0]
          : null;
      return (
        <Container>
          <Grid container spacing={5}>
            <Grid item sm={12} md={8} lg={8}>
              <Box
                border={"1px solid lightgrey"}
                borderRadius="12px"
                bgcolor={"#fff"}
              >
                <Box className={classes.coverImg}>
                  <Hidden smUp>
                    <Box position="absolute" top="8%" right="5%">
                      <IconButton className={classes.iconBtn} onClick={saveJob}>
                        <FavoriteBorder fontSize="small" />
                      </IconButton>
                      <IconButton className={classes.iconBtn}>
                        <Share fontSize="small" />
                      </IconButton>
                    </Box>
                  </Hidden>
                </Box>

                <Box padding={"3rem"} marginTop="-6rem">
                  <Avatar className={classes.avatar}>
                    <Typography variant="h4">
                      {job.company.companyName.charAt(0)}
                    </Typography>
                  </Avatar>
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    alignItems={"flex-start"}
                    marginBottom="2rem"
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        gutterBottom
                        style={{ fontWeight: 500 }}
                      >
                        {job.title}
                      </Typography>
                      <span className={classes.flex}>
                        <VerifiedUser
                          fontSize="small"
                          style={{ marginBottom: "5px" }}
                          color="secondary"
                        />
                        <Typography color="textSecondary" gutterBottom>
                          {job.company.companyName}{" "}
                          <span style={{ margin: "0 .5rem" }}>|</span> Spintex
                          Road
                        </Typography>
                      </span>
                      <span className={classes.flex}>
                        <WatchLater
                          fontSize="small"
                          style={{ marginBottom: "0px" }}
                          color="secondary"
                        />
                        <Typography color="textSecondary">
                          Posted - 4 days ago
                        </Typography>
                      </span>
                    </Box>

                    <Hidden xsDown>
                      <Box textAlign="right">
                        <span
                          className={classes.flex}
                          style={{
                            justifyContent: "flex-end",
                            marginBottom: "1rem",
                          }}
                        >
                          <Typography color="textSecondary">
                            {job ? job.applications.length : 0}{" "}
                            {job && job.applications.length === 1
                              ? "Applicant"
                              : "Applicants"}{" "}
                          </Typography>{" "}
                          |
                          <Typography color="textSecondary">
                            {job ? job.views : 0} Views{" "}
                          </Typography>
                        </span>

                        <Button
                          variant="contained"
                          startIcon={
                            isFavourite ? (
                              <Favorite fontSize="small" />
                            ) : (
                              <FavoriteBorder fontSize="small" />
                            )
                          }
                          onClick={saveJob}
                          disableElevation
                          size="medium"
                          style={{
                            borderRadius: "50px",
                            marginRight: ".8rem",
                            color: isFavourite && "#fff",
                            background: isFavourite && "#3156a2",
                          }}
                        >
                          {isFavourite ? "Saved" : "Save"}{" "}
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<Share fontSize="small" />}
                          disableElevation
                          size="medium"
                          style={{ borderRadius: "50px" }}
                        >
                          Share
                        </Button>
                      </Box>
                    </Hidden>
                  </Box>
                  <Card variant="outlined" style={{ borderRadius: "0px" }}>
                    <Grid container className={classes.grid}>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={3}
                        className={classes.gridItem}
                      >
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body2"
                        >
                          Industry
                        </Typography>
                        <Typography variant="body1" noWrap>
                          {job.industry}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={3}
                        className={classes.gridItem}
                      >
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body2"
                        >
                          Experience
                        </Typography>
                        <Typography variant="body1" noWrap>
                          {job.experience} Year(s)
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={3}
                        className={classes.gridItem}
                      >
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body2"
                        >
                          Qualification
                        </Typography>
                        <Typography variant="body1" noWrap>
                          {job.qualification}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={3}
                        className={classes.gridItem}
                      >
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body2"
                        >
                          Job Type
                        </Typography>
                        <Typography variant="body1" noWrap>
                          {job.jobType}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                  <Box marginTop={"2rem"}>
                    <Typography variant="h6" gutterBottom>
                      Job Overview
                    </Typography>
                    <Typography color="textSecondary">
                      {job.description}
                    </Typography>
                  </Box>
                  <Box marginTop={"2rem"}>
                    <Typography variant="h6" gutterBottom>
                      Requirements
                    </Typography>
                    <List disablePadding>
                      {job.requirements.map((el, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemIcon style={{ minWidth: "30px" }}>
                              <CheckCircle fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                              <Typography color="textSecondary">
                                {el}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Card
                variant="outlined"
                elevation={0}
                style={{ borderRadius: "10px" }}
              >
                {props.currentUser && !props.currentUser.isCompany ? (
                  <Box
                    padding={"1rem"}
                    textAlign="center"
                    bgcolor={"#3156a2"}
                    color="#fff"
                  >
                    <Typography variant="h6">
                      Career Profile (
                      {`${props.currentUser ? props.currentUser.total : 0}%`})
                    </Typography>
                  </Box>
                ) : null}
                <CardContent style={{ padding: "2rem" }}>
                  {props.currentUser && !props.currentUser.isCompany ? (
                    <>
                      {props.currentUser.firstname && (
                        <TextField
                          className={classes.field}
                          value={props.currentUser.firstname}
                          variant="outlined"
                          label="Firstname"
                          onChange={() => {}}
                          fullWidth
                        />
                      )}
                      {props.currentUser.lastname && (
                        <TextField
                          className={classes.field}
                          variant="outlined"
                          label="Lastname"
                          value={props.currentUser.lastname}
                          onChange={() => {}}
                          fullWidth
                        />
                      )}
                      {props.currentUser.email && (
                        <TextField
                          className={classes.field}
                          variant="outlined"
                          label="Email"
                          value={props.currentUser.email}
                          onChange={() => {}}
                          fullWidth
                        />
                      )}
                      {props.currentUser.phone && (
                        <TextField
                          className={classes.field}
                          variant="outlined"
                          label="Phone"
                          value={props.currentUser.phone}
                          onChange={() => {}}
                          fullWidth
                        />
                      )}
                      {props.currentUser.qualification && (
                        <TextField
                          className={classes.field}
                          variant="outlined"
                          label="Qualification"
                          value={props.currentUser.qualification}
                          onChange={() => {}}
                          fullWidth
                        />
                      )}
                      {props.currentUser.minimumExperience && (
                        <TextField
                          className={classes.field}
                          variant="outlined"
                          label="Experience"
                          value={props.currentUser.minimumExperience}
                          onChange={() => {}}
                          fullWidth
                        />
                      )}
                      {props.currentUser.skills.length > 0 && (
                        <TextField
                          className={classes.field}
                          variant="outlined"
                          label="Skills"
                          value={props.currentUser.skills.join(", ")}
                          onChange={() => {}}
                          fullWidth
                        />
                      )}
                      {props.currentUser.languages.length > 0 && (
                        <TextField
                          className={classes.field}
                          variant="outlined"
                          label="Languages"
                          value={props.currentUser.languages.join(", ")}
                          onChange={() => {}}
                          fullWidth
                        />
                      )}
                      {props.currentUser.resume && (
                        <Box
                          padding={"2rem"}
                          border="1px dashed lightgrey"
                          textAlign={"center"}
                          marginBottom="2rem"
                        >
                          <img src={PDF} alt="resume-upload" width="18%" />
                          <Typography>
                            {props.currentUser.resume.originalname}
                          </Typography>
                        </Box>
                      )}

                      <Button
                        variant="contained"
                        disabled={applied ? true : false}
                        disableElevation
                        color="primary"
                        onClick={() => applyJob()}
                        fullWidth
                        style={{ height: "3rem" }}
                      >
                        {applied ? "Submitted" : "Apply Now"}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Box marginBottom={"2rem"}>
                        <Typography variant="h6" gutterBottom>
                          Login to Apply
                        </Typography>
                        <Typography color="textSecondary">
                          Use a student account to apply for this job
                        </Typography>
                      </Box>
                      <TextField
                        className={classes.field}
                        variant="outlined"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                      />
                      <TextField
                        className={classes.field}
                        type={"password"}
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        label="Password"
                        fullWidth
                      />
                      <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={onFormSubmit}
                        fullWidth
                        style={{ height: "3rem", marginBottom: "1rem" }}
                      >
                        Login
                      </Button>
                      <Typography
                        style={{ display: "block", margin: "0 auto" }}
                        variant="body2"
                        paragraph
                        color="textSecondary"
                      >
                        Don't have an account?{" "}
                        <Link underline="none" href="/register">
                          Register here
                        </Link>
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      );
    }

    return <Loader />;
  };

  return (
    <div className={classes.root}>
      {props.modal && <Modal status={props.modal.status} />}
      {renderPage()}

      <SaveBox text={loader.text} image={User} open={loader.open} />

      {/* LOADER */}
      <Dialog open={save}>
        <Box padding={"2.5rem"} textAlign={"center"}>
          {/* <img src={User} alt='update' width={'25%'}/> */}
          <Typography paragraph>Updating Favourites. Please wait...</Typography>
          <CircularProgress size={30} />
        </Box>
      </Dialog>
    </div>
  );
};

const mapStatToProps = (state) => {
  return state;
};

export default connect(mapStatToProps, {
  errorModal,
  successModal,
  loginUser,
  jobApplication,
  markFavourite,
  unmarkFavourite,
  jobViews,
})(SingleJobList);

import { alpha, Box, Button, Container, Grid, Hidden, InputAdornment, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Search } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import Company from '../assets/banner.jpg'
import { connect } from 'react-redux'
import Modal from './Modal'
import { waitList, successModal, errorModal } from '../actions/actions'



const useStyles = makeStyles(theme => ({
    wrap: {
        marginTop: '-6rem',
        height: '100vh',
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0, 30%), rgba(0,0,0, 50%)), url(${Company})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    title : { 
        fontWeight: 600,
        fontSize: '2.8rem',
        color: '#fff',
        [theme.breakpoints.down('xs')]:{
            fontSize: '2.1rem'
        }
    },
    grid: {
        height: '100vh',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'

    },
    search: {
        width: '100%',
        marginTop: '1rem',
        background: '#fff',
        borderRadius: '50px',
        '& .MuiOutlinedInput-input': {
            height: '1.5rem'
        },
        '& .MuiOutlinedInput-root' : {
            borderRadius: '50px',
            border: `3px solid ${ alpha(theme.primaryColor, 0.9) }`,
            background: 'transparent'
        }
    },
    btn : {
        color:'#fff', 
        marginRight: '-6px', 
        height: '3rem',
        borderRadius: '50px',
    },
    searchIcon: {
        color: grey[400]
    }
}))

const Banner = (props) => {
    const classes = useStyles()


  return (
    <div className={classes.wrap}>
        {/* MODAL  */}
        { props.modal && <Modal status={props.modal.status} />}
        <Container>
            <Grid container >
                <Grid item sm={10} md={6} lg={8} className={classes.grid}>
                    
                    <Box style={{textAlign: 'center', width: '100%'}}>
                        <Typography variant='body2' color='textSecondary' style={{fontSize: '1.2rem', color: 'rgba(255,255,255, 0.8)'}} >Find the best opportunities</Typography>
                        <Typography variant='h3' className={classes.title} gutterBottom>Explore Thousands of Internship Openings</Typography>
                        <Hidden xsDown>
                            <TextField className={classes.search} placeholder='Search for openings' variant='outlined' fullWidth InputProps={{
                                endAdornment: <InputAdornment position='end'>
                                    <Button size='large' variant='contained' disableElevation color='primary' onClick={()=>{}} className={classes.btn}>Find Internships</Button> 
                                    </InputAdornment>,
                                startAdornment: <InputAdornment position='start'> <Search className={classes.searchIcon} /> </InputAdornment>
                                }} />
                        </Hidden>
                        <Hidden smUp>
                            <TextField className={classes.search} placeholder='Search for openings' variant='outlined' fullWidth InputProps={{
                                endAdornment: <InputAdornment position='end'>
                                    <Button variant='contained' disableElevation color='primary' onClick={()=>{}} className={classes.btn}>Search</Button> 
                                    </InputAdornment>,
                                startAdornment: <InputAdornment position='start'> <Search className={classes.searchIcon} /> </InputAdornment>
                            }} />
                        </Hidden>
                    </Box>

                </Grid>
            </Grid>

        </Container>
    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {waitList, successModal, errorModal})(Banner)
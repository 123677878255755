import { Box, Button, Chip, Dialog, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useReducer, useState } from 'react'
import PayStackPop from '@paystack/inline-js'
import { connect } from 'react-redux'
import Modal from './Modal'
import { successModal, errorModal, paymentPlan } from '../actions/actions'
import SaveBox from './SaveBox'
import Finish from '../assets/finished.png'
import CheckList from '../assets/checklist.gif'



const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '2.5rem',
    fontWeight: 700,
  },
  icon: {
    minWidth: '30px',
    color: theme.secondaryColor
  },
  btn: {
    background: theme.primaryColor,
    color: '#fff',
    height:'2.8rem',
    borderRadius: '10px',
    marginTop: '1rem'
  },
  title2: {
    fontSize: '1.5rem',
    fontWeight: 800,
  },
  field: {
    marginBottom: '1rem'
  }
}))

const PricingPlan = (props) => {
  const classes = useStyles()
  const[open, setOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const [loader, setLoader] = useState({open: false, text: ''})
  const [pay, setPay] = useState(false)

  const basic = ['2 Job Listings', 'Unlimited Candidates', '1 Collaborator']
  const standard = ['5 Job Listings', 'Unlimited Candidates', '2 Collaborators']
  const professional = ['10 Job Listings', 'Unlimited Candidates', '5 Collaborators']

  const payState = {
    firstname: '',
    lastname: '',
    phone: ''
  }

const paymentReducer = (state, action) => {
    switch (action.type) {
      case "FNAME":
        return { ...state, firstname: action.payload}
      case "LNAME":
        return { ...state, lastname: action.payload}
      case "PHONE":
        return { ...state, phone: action.payload}
      case "RESET":
        return payState
      default:
        return state
    }
  }

const [payInput, payDispatch] = useReducer(paymentReducer, payState)

  const onSelect = (name, price, includes) => {
    // check if user is avaibale
    if(!props.currentUser){
      window.location.assign('/login')
    }
    // check if user is company
    if(props.currentUser.isCompany === false){
      props.errorModal('Sorry, please create a Company account to select a product')
      return
    }
    let credit;
    if(name === 'Basic') credit = 2
    if(name === 'Standard') credit = 5
    if(name === 'Professional') credit = 10
    const data = {
      name, price,
      credit,
      includes: includes
    }
    setSelected(data)
    setOpen(true)
  }

  const makePayment = () => {
    //initialize PayStack
   const paystack = new PayStackPop()
   paystack.newTransaction({
        key: 'pk_test_c84e92927793f9dc742a61b7c5c658db1ae02dc6',
        amount: selected.price * 100,
        email: props.currentUser && props.currentUser.email, 
        firstname: payInput.firstname,
        lastname: payInput.lastname,
        phone: payInput.phone,
      onSuccess(transaction){
        console.log(transaction)
        if(transaction.status === 'success'){
          payDispatch('RESET')
          setOpen(false)
          setSelected(null)
          setLoader({open: true, text: 'Payment Successful. Creating you plan, please wait...'})

          //create Plan in Database
          setTimeout(async() => {
            const res = await props.paymentPlan(selected)
            if(res.status === 'success'){
              setLoader({open: false, text: ''})
              setPay(true)
            }else {
              setLoader({open: false, text: ''})
              props.errorModal(res.message)
            }
          }, 1500);
        }else{
          props.errorModal('Payment failed. Refresh and try again')
        }



      },
      onCancel(){
        props.errorModal('Payment Cancelled')
        setOpen(false)
        setSelected(null)
      }
    })
  }

  return (
    <>
    {props.modal && <Modal status={props.modal.status} />}
        <Grid container spacing={3} >
          <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box padding={'2rem'} borderRadius='15px' border='1px solid lightgrey' bgcolor={'#fff'}>
                <Chip label='Basic' color='secondary' style={{marginBottom: '3rem', color: '#fff'}} />
                <Typography className={classes.title} variant='h6'>GHc 120</Typography>
                <Typography color='textSecondary' variant='body1' >Ideal plan for all users</Typography>
                <Divider style={{margin: '1rem 0'}} />

                <List disablePadding>
                  {
                    basic.map((el, index) => {
                      return (
                        <ListItem key={index} disableGutters>
                          <ListItemIcon className={classes.icon}>
                            <CheckCircle />
                          </ListItemIcon>
                          <ListItemText>{el}</ListItemText>
                        </ListItem>
                      )
                    })
                  }
                </List>
                <Button variant='contained' disableElevation fullWidth className={classes.btn} onClick={() => onSelect('Basic', 120, basic)}>Select Plan</Button>
              </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box padding={'2rem'} borderRadius='15px' border='1px solid lightgrey' bgcolor={'#fff'}>
                <Chip label='Standard' color='secondary' style={{marginBottom: '3rem', color: '#fff'}} />
                <Typography className={classes.title} variant='h6'>GHc 300</Typography>
                <Typography color='textSecondary' variant='body1'>Plan for small teams</Typography>
                <Divider style={{margin: '1rem 0'}} />

                <List disablePadding>
                  {
                    standard.map((el, index) => {
                      return (
                        <ListItem key={index} disableGutters>
                          <ListItemIcon className={classes.icon}>
                            <CheckCircle />
                          </ListItemIcon>
                          <ListItemText>{el}</ListItemText>
                        </ListItem>
                      )
                    })
                  }
                </List>
                <Button variant='contained' disableElevation fullWidth className={classes.btn} onClick={() => onSelect('Standard', 300, standard)}>Select Plan</Button>
              </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box padding={'2rem'} borderRadius='15px' border='1px solid lightgrey' bgcolor={'#fff'}>
                <Chip label='Professional' color='secondary' style={{marginBottom: '3rem', color: '#fff'}} />
                <Typography className={classes.title} variant='h6' >GHc 450</Typography>
                <Typography color='textSecondary' variant='body1'>Plan for professionals</Typography>
                <Divider style={{margin: '1rem 0'}} />

                <List disablePadding>
                  {
                    professional.map((el, index) => {
                      return (
                        <ListItem key={index} disableGutters>
                          <ListItemIcon className={classes.icon}>
                            <CheckCircle />
                          </ListItemIcon>
                          <ListItemText>{el}</ListItemText>
                        </ListItem>
                      )
                    })
                  }
                </List>
                <Button variant='contained' disableElevation fullWidth className={classes.btn} onClick={() => onSelect('Professional', 450, professional)}>Select Plan</Button>
              </Box>
          </Grid>

        </Grid>

        {
                selected && open ?
                    <Dialog open={ open } maxWidth='md' fullWidth onClose={()=> setOpen(false)}>
                        <Box padding='5rem' textAlign={'center'} width='auto' >
                            <Typography style={{fontWeight: 600}} variant='h5'>Pricing Plan</Typography>
                            <Typography variant='body1' color='textSecondary' style={{marginBottom: '2rem'}}>Choose the plan tailored to your needs</Typography>

                                <Box textAlign={'left'} width={'100%'}>
                                    <Grid container spacing={7} style={{margin: '0'}}>
                                        <Grid item sm={8} style={{border: '1px solid lightgrey', borderRadius: '10px', padding: '2rem'}}>
                                            <Typography variant='h6' gutterBottom>Payment</Typography>
                                            {/* <TextField className={classes.field} select defaultValue={''} variant='outlined' value={payInput.network} fullWidth label='Select Network' onChange={(e)=> payDispatch({type:"NETWORK", payload: e.target.value})}> 
                                                {
                                                    ['MTN', 'AirtelTigo', 'Vodafone'].map((el, index) => {
                                                        return (
                                                            <MenuItem key={index} value={el}>{el}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </TextField> */}
                                             <TextField className={classes.field} variant='outlined' value={props.currentUser && props.currentUser.email} fullWidth label='Email' disabled />
                                            <TextField className={classes.field} variant='outlined' value={payInput.firstname} fullWidth label='First Name' onChange={(e)=> payDispatch({type:"FNAME", payload: e.target.value})} />
                                            <TextField className={classes.field} variant='outlined' value={payInput.lastname} fullWidth label='Last Name' onChange={(e)=> payDispatch({type:"LNAME", payload: e.target.value})}/>
                                            <TextField className={classes.field} variant='outlined' value={payInput.phone} style={{marginBottom: '0'}} fullWidth label='Phone' onChange={(e)=> payDispatch({type:"PHONE", payload: e.target.value})}/>
                                        </Grid>

                                        <Grid item sm={4}>
                                            <Box padding={'2rem'} borderRadius='15px' bgcolor={'#faa831'}>
                                                <Typography>{selected.name}</Typography>
                                                <Typography className={classes.title2} variant='h6'>GHc {selected.price}</Typography>
                                                <Typography color='textSecondary' variant='body2'>The perfect plan choice</Typography>
                                                <Divider style={{margin: '1rem 0'}} />
                                                <Button variant='contained' disableElevation color='primary' fullWidth className={classes.btn} onClick={makePayment}>Make Payment</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                        </Box>

                    </Dialog>
                : null
            }

            {/* PAYMENT POPUP */}
              <Dialog open={pay} maxWidth='md'>
                  <Box textAlign={'center'} padding='2rem'>
                    <img src={Finish} alt='finish' width={'30%'} style={{marginBottom: '2rem'}}  />
                    <Typography variant='h5' gutterBottom>Success</Typography>
                    <Typography color='textSecondary' gutterBottom>You have successfully purchased a {selected && selected.name} plan . <br/>Click "Post a Job" to create a new job</Typography>
                    <Button variant='contained' disableElevation onClick={()=>setPay(false)} color='secondary' style={{color:"#fff", marginTop: '1rem'}} >Post a Job</Button>
                  </Box>
              </Dialog>

        {/* LOADER */}
        <SaveBox open={loader.open} text={loader.text} image={CheckList} />
    
    </>
  )
}


const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {successModal, errorModal, paymentPlan})(PricingPlan)
import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
   
    typography : {
        fontFamily: "Poppins",
        fontSize: 13
    },
    palette : {
        primary: {
            main: '#3156a2'
        },
        secondary : {
            main: '#faa831'
        }
    },
    primaryColor: '#3156a2',
    secondaryColor: '#faa831'
})
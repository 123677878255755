import { Avatar, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { CheckCircle, KeyboardArrowLeft } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useReducer, useState } from 'react'
import { connect } from 'react-redux'
import Listing from '../../assets/listing.png'
import Finish from '../../assets/finished.png'
import CheckList from '../../assets/checklist.gif'
import PricingPlan from '../../components/PricingPlan'
import { errorModal, successModal, newJob, paymentPlan } from '../../actions/actions'
import Modal from '../../components/Modal'
import SaveBox from '../../components/SaveBox'



const useStyles = makeStyles(theme => ({
  
  svg : {
    background: grey[200],
    borderRadius: '50%',
  },
  field: {
    marginBottom: '1.5rem'
  },
  label: {
    marginBottom: '.5rem'
  },
title: {
    fontSize: '1.5rem',
    fontWeight: 800,
  },
  icon: {
    minWidth: '30px',
    color: theme.secondaryColor
  },
  btn: {
    color: '#fff',
    height:'2.8rem',
    borderRadius: '10px',
    marginTop: '1rem'
  },
  listIcon: {
    '& .MuiListItemIcon-root':{
      minWidth:'30px'
    }
  }
}))

const PostAJob = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState({open: false, text: ''})
  const [pay, setPay] = useState(false)
  const [selected, setSelected] = useState(null)
  const [done, setDone] = useState(false)
  const industries = [
    'Advertising & Communications',
    'Agriculture & Forestry',
    'Automotive & Aviation', 
    'Banking & Finance', 
    'Construction',
    'Design & Art',
    'Education',
    'Energy & Utilities',
    'Enforcement & Security',
    'Entertainment & Sport',
    'Hospitality & Hotel',
    'I.T & Software',
    'Law & Compliance',
    'Mining & Energy',
    'Real Estate'
  ]

  const jobState = {
    title: '',
    description: '',
    industry: '',
    experience: '',
    qualification: '',
    jobType: '',
    requirements: []
  }

  const payState = {
    network: '',
    accName: '',
    accNumber: ''
  }

  const convertReq = (val) => {
    const res = val.split(',')
    return res
  }

  const jobReducer = (state, action) => {
    switch (action.type) {
      case 'TITLE':
        return {...state, title: action.payload}
      case 'DESC':
        return {...state, description: action.payload}
      case 'INDUSTRY':
        return {...state, industry: action.payload}
      case 'EXP':
        return {...state, experience: action.payload}
      case 'QUA':
        return {...state, qualification: action.payload}
      case 'TYPE':
        return {...state, jobType: action.payload}
      case 'REQ':
        const result = convertReq(action.payload)
        return {...state, requirements: [...result]}
      case 'RESET':
        return jobState
      default:
        return state;
    }
  }

  const paymentReducer = (state, action) => {
    switch (action.type) {
      case "NETWORK":
        return {...state, network: action.payload}
      case "ACC_NUMBER":
        return { ...state, accNumber: action.payload}
      case "ACC_NAME":
        return { ...state, accName: action.payload}
      case "RESET":
        return payState
      default:
        return state
    }
  }

  const [formInput, dispatch] = useReducer(jobReducer, jobState)
  const [payInput, payDispatch] = useReducer(paymentReducer, payState)

    const onPlanSelect = (obj) => {
        setSelected(obj)
    }

    const goBack = () => {
        setSelected(null)
    }

    const trimInput = (val, result) => {
      for (let index = 0; index < val.length; index++) {
        result.push(val[index].trim());
      }
    }

    const onFormSubmit = () => {
      if(formInput.title === '' || formInput.description === '' || formInput.industry === '' || formInput.experience === '' || formInput.qualification === '' || formInput.jobType === '' || formInput.requirements.length === 0){
        props.errorModal('Invalid. Provide all fields')
        return
      }
      const reqs = [];
      trimInput(formInput.requirements, reqs)

      const data = {
        ...formInput,
        requirements: reqs
      }
      console.log(data)
      setLoader({open: true, text: 'Creating your job. Please wait..'})

      setTimeout(async() => {
        const res = await props.newJob(data)
        if(res.status === 'success'){
          dispatch({type: "RESET"})
          setLoader({open: false, text: ''})
          setDone(true)
        }else {
          setLoader({open: false, text: ''})
          props.errorModal('Sorry, something went wrong. Please refresh and try again')
        }
      }, 1000);

    }

    const makePayment = () => {
      if(payInput.network === ''){
        props.errorModal('Please select a Mobile Network')
        return
      }
      if(payInput.accNumber === '' || payInput.accNumber.length < 10){
        props.errorModal('Sorry, please provide a valid phone number')
        return
      }
      if(payInput.accName === ''){
        props.errorModal('Please provide account name')
        return
      }
      // make data
      console.log(payInput)
      const payPlan = selected

      setLoader({open: true, text: 'Processing payment. Please wait...'})
      // process payment here ...

      // connect to db
      setTimeout(async() => {
        const res = await props.paymentPlan(payPlan)
        if(res.status === 'success'){
          payDispatch({type: "RESET"})
          setLoader({open: false, text: ''})
          setOpen(false)
          setSelected(null)
          setPay(true)
        }else {
          setLoader({open: false, text: ''})
          props.errorModal(res.message)
        }
      }, 1000);
    }

    const calcPercent = (a, b) => {
      const total = a + b
      const res = (b / total) * 100
      return 100 - res
    }


  return (
    <div>
      { props.modal && <Modal status={props.modal.status} />}
      <Container>
            <Box marginBottom={'2rem'}>
                <Typography variant='h5'>New Job Listing</Typography>
            </Box>


            <Grid container spacing={3}>
              <Grid item sm={9}>
                {
                  props.currentUser && props.currentUser.plan && props.currentUser.plan.credit > 0 ?
                  
                  <Card variant='outlined' elevation={0}>
                    <CardContent style={{padding: '2.5rem'}}>
                        <Typography variant='body1' className={classes.label} >Job Title</Typography>
                        <TextField className={classes.field} variant='outlined' placeholder='Enter your job title' fullWidth value={formInput.title} onChange={(e) => dispatch({type: 'TITLE', payload: e.target.value})}/>
                        
                        <Typography variant='body1' className={classes.label} >Job Description</Typography>
                        <TextField className={classes.field} variant='outlined' multiline rows={7} value={formInput.description} onChange={(e) => dispatch({type: 'DESC', payload: e.target.value})}
                        placeholder='Briefly describe this job' fullWidth/>

                        <Grid container spacing={3}>
                          <Grid item lg={6}>
                              <Typography variant='body1' className={classes.label} >Industry</Typography>
                              <TextField className={classes.field} select defaultValue={''} value={formInput.industry} onChange={(e) => dispatch({type: 'INDUSTRY', payload: e.target.value})}
                              variant='outlined' label='Select Industry' fullWidth>
                                {
                                  industries.map((el, index) => {
                                    return (
                                      <MenuItem key={index} value={el}>{el}</MenuItem>
                                      )
                                  })
                                }
                             </TextField>
                          </Grid>
                          <Grid item lg={6}>
                              <Typography variant='body1' className={classes.label} >Years of Experience</Typography>
                              <TextField className={classes.field} select defaultValue={''} variant='outlined' value={formInput.experience} onChange={(e) => dispatch({type: 'EXP', payload: e.target.value})} 
                              label='Experience' fullWidth>
                                {
                                  ['Less than a year','1', '2', '3', '4', '5', '10', '20'].map((el, index) => {
                                    return (
                                      <MenuItem key={index} value={el}>{el} {el === 'Less than a year' ? null : 'Year(s)'}</MenuItem>
                                      )
                                  })
                                }
                             </TextField>
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item lg={6}>
                              <Typography variant='body1' className={classes.label} >Minimum Qualification</Typography>
                              <TextField className={classes.field} select defaultValue={''} value={formInput.qualification} onChange={(e) => dispatch({type: 'QUA', payload: e.target.value})}
                               variant='outlined' label='Qualification' fullWidth>
                                {
                                  ['Degree', 'HND (Higher National Diploma)', 'Diploma', 'Certificate'].map((el, index) => {
                                    return (
                                      <MenuItem key={index} value={el}>{el}</MenuItem>
                                      )
                                  })
                                }
                             </TextField>
                          </Grid>
                          <Grid item lg={6}>
                              <Typography variant='body1' className={classes.label} >Job Type</Typography>
                             <TextField className={classes.field} select defaultValue={''} value={formInput.jobType} onChange={(e) => dispatch({type: 'TYPE', payload: e.target.value})}
                              variant='outlined' label='Select Type' fullWidth>
                                {
                                  ['Full Time', 'Remote', 'Internship', 'Contract'].map((el, index) => {
                                    return (
                                      <MenuItem key={index} value={el}>{el}</MenuItem>
                                    )
                                  })
                                }
                             </TextField>
                          </Grid>
                        </Grid>

                        <Typography variant='body1' className={classes.label} >Job Requirements (Each requirement should be seperated by a comma)</Typography>
                        <TextField className={classes.field} variant='outlined' value={formInput.requirements} onChange={(e) => dispatch({type: 'REQ', payload: e.target.value})}
                         multiline rows={8} placeholder='Eg. Great communication skills, Good Team player, Ability to work under pressure, etc' fullWidth/>
                        
                        <Button variant='contained' color='primary' size='large' onClick={onFormSubmit}
                          style={{ marginTop: '1rem', display: 'block'}} disableElevation >Create A Job</Button>
                    </CardContent>
                  </Card>
                  :
                  <Box padding={'6rem'} bgcolor={'#fff'} border='1px dashed lightgrey' borderRadius={'10px'} marginBottom={'3rem'}>
                      <Box textAlign={'center'} marginBottom='2rem'>
                          <img src={Listing} alt='job-listing' width='20%' style={{margin:'0 auto', marginBottom: '2rem'}} />
                          <Typography variant='h5' gutterBottom>You Have 0 Job Listing Credit</Typography>
                      </Box>
                      <Grid container spacing={4}>
                          <Grid item sm={6} style={{display: 'flex', gap: '1rem', alignItems: 'flex-start'}}>
                              <Avatar>1</Avatar>
                              <span>
                                  <Typography style={{fontSize: '1rem'}} variant='h6'>Select a Plan</Typography>
                                  <Typography color='textSecondary'>Choose a plan that best suites your needs to start listing your jobs</Typography>
                              </span>
                          </Grid>
                          <Grid item sm={6} style={{display: 'flex', gap: '1rem',alignItems: 'flex-start' }}>
                              <Avatar>2</Avatar>
                              <span>
                                  <Typography style={{fontSize: '1rem'}} variant='h6'>List Your Job</Typography>
                                  <Typography color='textSecondary'>Choose a plan that best suites your needs to start listing your jobs</Typography>
                              </span>
                          </Grid>
                          
                      </Grid>
                      <Button variant='contained' color='primary' size='large' onClick={()=> setOpen(true)}
                          style={{margin: '0 auto', marginTop: '2rem', display: 'block'}} disableElevation >Create A Job</Button>
                  </Box>

                }

              </Grid>

              <Grid item sm={3}>
                  <Card variant='outlined'>
                    <Box padding='1.3rem' bgcolor={'#3156a2'} color='#fff' textAlign={'center'}>
                      <Typography variant='h6'>{ props.currentUser && props.currentUser.plan ? `${props.currentUser.plan.name} Plan` : 'No Active Plan' }</Typography>
                    </Box>
                    <CardContent>
                        <Box textAlign='center'>
                          <Box padding={'2rem'} position={'relative'} display={'flex'} justifyContent='center' alignItems={'center'}>
                            <CircularProgress className={classes.svg} color='secondary' variant='determinate' value={props.currentUser && props.currentUser.plan ? calcPercent(props.currentUser.plan.credit, props.currentUser.plan.used) : 0} size={180} thickness={8} />
                              <Box position={'absolute'}>
                                <Typography variant='h6' style={{fontSize: '3rem', margin: '-18px 0'}}>{props.currentUser && props.currentUser.plan ? `0${props.currentUser.plan.credit}` : '00'}</Typography>
                                <Typography variant='body2' color='textSecondary'>Listing Left</Typography>
                              </Box>

                          </Box>
                          {
                            props.currentUser && props.currentUser.plan ?
                            <>
                              <Divider style={{marginBottom: '1rem'}} />
                              <Typography variant='h6' style={{margin: '.5rem 1rem 0', textAlign: 'left' }}>Whats included</Typography>
                              <List disablePadding >
                                  {
                                    props.currentUser && props.currentUser.plan ? props.currentUser.plan.includes.map((el, index) => {
                                      return (
                                        <ListItem dense key={index}>
                                          <ListItemIcon style={{minWidth: '30px'}}><CheckCircle fontSize='small'  color='secondary' /></ListItemIcon>
                                          <ListItemText>{el}</ListItemText>
                                        </ListItem>
                                      )
                                    })
                                    : null
                                  } 
                                
                              </List>
                            
                            </> :  null
                          }
                        </Box>
                    </CardContent>
                  </Card>
              </Grid>
            </Grid>


        </Container>

        <Dialog open={open} maxWidth='md' fullWidth onClose={()=> setOpen(false)}>
            <Box padding='5rem' textAlign={'center'} width='auto' >
                <Typography style={{fontWeight: 600}} variant='h5'>Pricing Plan</Typography>
                <Typography variant='body1' color='textSecondary' style={{marginBottom: '2rem'}}>Choose the plan tailored to your needs</Typography>

                {  !selected &&
                    <Box>
                        <PricingPlan select={onPlanSelect} />
                    </Box>
                }

                {
                    selected && 
                    <Box textAlign={'left'} width={'100%'}>
                        <Button variant='text' startIcon={<KeyboardArrowLeft />} style={{textTransform: 'none', marginBottom: '1rem'}} onClick={goBack}>Go Back</Button>
                        
                        <Grid container spacing={7} style={{margin: '0'}}>
                            <Grid item sm={8} style={{border: '1px solid lightgrey', borderRadius: '10px', padding: '2rem'}}>
                                <Typography variant='h6' gutterBottom>Payment</Typography>
                                <TextField className={classes.field} select defaultValue={''} variant='outlined' value={payInput.network} fullWidth label='Select Network' onChange={(e)=> payDispatch({type:"NETWORK", payload: e.target.value})}> 
                                    {
                                        ['MTN', 'AirtelTigo', 'Vodafone'].map((el, index) => {
                                            return (
                                                <MenuItem key={index} value={el}>{el}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                                <TextField className={classes.field} variant='outlined' value={payInput.accNumber} fullWidth label='Mobile Money Number' onChange={(e)=> payDispatch({type:"ACC_NUMBER", payload: e.target.value})} />
                                <TextField className={classes.field} variant='outlined' value={payInput.accName} style={{marginBottom: '0'}} fullWidth label='Account Name' onChange={(e)=> payDispatch({type:"ACC_NAME", payload: e.target.value})}/>
                            </Grid>

                            <Grid item sm={4}>
                                <Box padding={'2rem'} borderRadius='15px' bgcolor={'#faa831'}>
                                    <Typography>{selected.name}</Typography>
                                    <Typography className={classes.title} variant='h6'>GHc {selected.price}</Typography>
                                    <Typography color='textSecondary' variant='body2'>The perfect plan choice</Typography>
                                    <Divider style={{margin: '1rem 0'}} />
                                    <Button variant='contained' disableElevation color='primary' fullWidth className={classes.btn} onClick={makePayment}>Make Payment</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                }

            </Box>

        </Dialog>

        {/* SUCCESS POPUP */}
        <Dialog open={done} maxWidth='md'>
            <Box textAlign={'center'} padding='2rem'>
              <img src={Finish} alt='finish' width={'30%'} style={{marginBottom: '2rem'}}  />
              <Typography variant='h5' gutterBottom>Congratulations!!</Typography>
              <Typography color='textSecondary' gutterBottom>You have successfully created a new job.</Typography>
              <Button variant='contained' disableElevation href='/company/listing' color='secondary' style={{color:"#fff", marginTop: '1rem'}} >View Job</Button>
              <Button variant='contained' disableElevation onClick={()=>window.location.reload()} color='secondary' style={{color:"#fff", marginTop: '1rem', marginLeft:'1rem'}} >Create A New Job</Button>
            </Box>
        </Dialog>

        {/* PAYMENT POPUP */}
        <Dialog open={pay} maxWidth='md'>
            <Box textAlign={'center'} padding='2rem'>
              <img src={Finish} alt='finish' width={'30%'} style={{marginBottom: '2rem'}}  />
              <Typography variant='h5' gutterBottom>Payment Success!!</Typography>
              <Typography color='textSecondary' gutterBottom>You have successfully purchased a {selected && selected.name} plan . <br/>Click "Post a Job" to create a new job</Typography>
              <Button variant='contained' disableElevation onClick={()=>setPay(false)} color='secondary' style={{color:"#fff", marginTop: '1rem'}} >Post a Job</Button>
            </Box>
        </Dialog>

        {/* LOADER */}
        <SaveBox open={loader.open} text={loader.text} image={CheckList} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {errorModal, successModal, newJob, paymentPlan})(PostAJob)
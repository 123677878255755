import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import {Avatar, Button, Container, Grid, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Modal from "../components/Modal";
import { connect } from "react-redux";
import { verifyEmail, resendEmail } from "../actions/actions";
import { Navigate, useLocation } from "react-router-dom";
import { Drafts } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDivider-root": {
      flexGrow: 1,
    },
  },
  wrapper: {
    textAlign: 'center',
    display: "flex",
    flexDirection: "column",
    padding: "3.2rem",
    borderRadius: "16px",
    boxShadow: "-24px 24px 72px 8px rgb(145 158 171 / 24%)",
    "& a": {
      color: theme.primaryColor,
      fontWeight: 400,
    },
  },
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    marginBottom: ".5rem",
    fontWeight: 500,
  },
  field: {
    textAlign: "center",
    marginBottom: "1rem",
    borderRadius: "1.6rem",
    "& *": {
      borderRadius: "8px",
    },
    "& label.Mui-focused": {
      color: theme.primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColor}`,
      },
    },
  },
  btn: {
    padding: "1rem 0",
    color: "#fff",
    borderRadius: "8px",
    marginBottom: "2rem",
    "&:hover": {
      background: theme.secondaryColor,
    },
  },
  btnGoogle: {
    padding: "1rem 0",
    background: grey[300],
    borderRadius: "8px",
  },
  flex: {
    marginBottom: "2rem",
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
  },
  fieldIcon: {
    color: grey[400],
    cursor: "pointer",
  },
  icon: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: '0 auto',
    marginBottom: '2rem',
  }
}));



const Verify = (props) => {
  const classes = useStyles();
  const { verifyEmail } = props
  const[disable, setDisable] = useState(false)
  const token = useLocation().search.split('=')[1]


  useEffect(() => {
    if(token === undefined){
      return;
    }else {
      console.log(token)
      verifyEmail(token)
    }
  },[verifyEmail, token])

  const onFormSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    //call action creators
    props.resendEmail()
  };


  const renderPage =() => {
    if(props.currentUser){
        if(!props.currentUser.isEmailVerified){
          return <Container className={classes.root}>
          
          {/* MODAL  */}
          {props.modal && <Modal status={props.modal.status} />}

          <Grid container style={{ justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
          
            <Grid item xs={12} lg={5} style={{margin: '0 auto'}}>
                <Avatar className={classes.icon} ><Drafts style={{fontSize: '3.5rem'}} /></Avatar>
                <div className={`${classes.wrapper}`}>
                  <Typography className={classes.title} variant="h5">  Verify Your Email </Typography>
                  <Typography style={{ marginBottom: "2.5rem" }} paragraph color="textSecondary" >
                    Please check your email ({props.currentUser.email})<br/> to verify your account
                  </Typography>

                  <Button className={classes.btn} disabled={disable} variant="contained" disableElevation type="submit" color="primary" fullWidth onClick={onFormSubmit}>
                    Resend Email
                  </Button>

                </div>
            </Grid>
          </Grid>
        </Container>
        }
    }

    return <Navigate to='/' />
  }

  return (
    <>
      {renderPage()}
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { verifyEmail, resendEmail })(Verify);

import { alpha, Avatar, Box, Button, Card, Container, Grid, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@material-ui/core'
import { AccountBalance, ArrowForward, Description, Email, Person, PlayCircleFilled, Work } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import Rating from '@material-ui/lab/Rating';
import React from 'react'
import Banner from '../components/Banner'
import VideoBg from '../assets/how-it-works.jpg'
import { grey } from '@material-ui/core/colors';
import { connect } from 'react-redux';



const useStyles = makeStyles(theme => ({
    title : {
        fontWeight: 600,
        textAlign: 'center',
        fontSize: '2.2rem',
        '& span' : {
            color: theme.primaryColor
        }
    },
    category : {
        boxShadow: `0 5px 25px 0 rgb(41 128 185 / 15%)`,
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '15rem',
        [theme.breakpoints.down('sm')]: {
            height: '12rem'
        }

    },
    company : {
        padding: '2rem',
        boxShadow: `0 5px 25px 0 rgb(41 128 185 / 15%)`,
        background: '#fff',
    },
    section: {
        margin : '5.5rem 0'
    },
    icon : {
        color: theme.primaryColor,
        fontSize: '2.5rem',
        marginBottom: '1rem'
    },
    large : {
        width: theme.spacing(9),
        height: theme.spacing(9),
        background: theme.primaryColor,
        border: `8px solid ${alpha('#000', 0.05)}`,
        [theme.breakpoints.down('sm')]:{
            width: theme.spacing(6),
            height: theme.spacing(6),
        }
    },
    work: {
        alignItems: 'top',
        '& .MuiListItemText-root span': {
            fontSize: '1.3rem',
            marginBottom: '6px',
            fontWeight: 400
        },
        '& .MuiListItemText-root p' : {
            fontSize: '.9rem'
        }
    },
    listIcon : {
        color: '#fff',
        fontSize: '2.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem'
        }
    },
    video : {
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundImage: `linear-gradient(0deg, #000000a3, transparent), url(${VideoBg})`,
        backgroundSize: 'cover',
        [theme.breakpoints.down('sm')]: {
            height: '20rem'
        }
    },
    videoIcon : {
        color: '#fff',
        fontSize: '3.5rem',
    }, 
    companyIcon: {
        width: theme.spacing(5),
        height: theme.spacing(5)
    },
    search: {
        marginTop: '1rem',
        background: '#fff',
        borderRadius: '50px',
        '& .MuiOutlinedInput-input': {
            height: '2rem'
        },
        '& .MuiOutlinedInput-root' : {
            borderRadius: '50px',
            border: `10px solid ${grey[300]}`
        }
    },
    btn : {
        color:'#fff', 
        marginRight: '-6px', 
        height: '3.4rem',
        borderRadius: '50px',
    },
    searchIcon: {
        color: grey[400]
    },
    truncate: {
        textOverflow: 'ellipsis',
        overflow:'hidden',
        // Addition lines for 2 line or multiline ellipsis
        display: '-webkit-box !important',
        "-webkit-line-clamp": '3',
        "-webkit-box-orient": 'vertical',
        whiteSpace: 'normal',
    }

}))
const Home = (props) => {
    const classes = useStyles()

    const categories = [
        {name: 'Accounting', icon: <AccountBalance className={classes.icon} />, available: 3},
        {name: 'Business', icon: <AccountBalance className={classes.icon} />, available: 3},
        {name: 'Education', icon: <AccountBalance className={classes.icon} />, available: 3},
        {name: 'Healthcare', icon: <AccountBalance className={classes.icon} />, available: 3},
        {name: 'Food & Restuarant ', icon: <AccountBalance className={classes.icon} />, available: 3},
        {name: 'Transport', icon: <AccountBalance className={classes.icon} />, available: 3},
        {name: 'IT & Computing', icon: <AccountBalance className={classes.icon} />, available: 3},
        {name: 'Telecommunications', icon: <AccountBalance className={classes.icon} />, available: 3}
    ]


  return (
    <>
        <Banner />
        <Container>

            {/* CATEGORIES */}
            <Box className={classes.section}>
                <Typography variant='h3' className={classes.title}>Browse By <span>Category</span></Typography>
                <Typography paragraph color='textSecondary' style={{textAlign: 'center', margin: '1rem 0 3rem 0'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</Typography>

                    <Grid container spacing={3}>
                    { categories.map(item => {
                        return (
                            <Grid item xs={12} md={4} lg={3}  key={item.name} style={{padding: '1rem'}}>
                                <Card className={classes.category}>
                                    <Box>{item.icon}</Box>
                                    <Typography variant='h6' noWrap>{item.name}</Typography>
                                    <Typography paragraph color='textSecondary'>{item.available} Openings</Typography>
                                </Card>
                            </Grid>
                        )
                    })
                    }

                    </Grid>

            </Box>

            {/* HOW IT WORKS */}
            <Box className={classes.section} marginBottom='2rem'>
                <Typography variant='h3' className={classes.title}>How It <span>Works</span></Typography>
                <Typography paragraph color='textSecondary' style={{textAlign: 'center', margin: '1rem 0 3rem 0'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Card className={classes.video} elevation={0}>
                            <IconButton style={{background: 'rgba(0,0,0, 0.3)'}}>
                                <PlayCircleFilled className={classes.videoIcon} />
                            </IconButton>
                        </Card>
                    </Grid> 

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <List style={{padding: '1.5rem'}}>
                                <ListItem className={classes.work}>
                                    <ListItemAvatar style={{marginRight: '2rem'}}>
                                        <Avatar className={classes.large}><Person className={classes.listIcon} /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Create An Account' secondary='Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt. consectetur adipiscing elit, sed do eiusmod tempor incididunt' />
                                </ListItem>
                                <ListItem className={classes.work}>
                                    <ListItemAvatar style={{marginRight: '2rem'}}>
                                        <Avatar className={classes.large}><Description className={classes.listIcon} /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Apply For Internship' secondary='Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt. consectetur adipiscing elit, sed do eiusmod tempor incididunt' />
                                </ListItem>
                                <ListItem className={classes.work}>
                                    <ListItemAvatar style={{marginRight: '2rem'}}>
                                        <Avatar className={classes.large}><Work className={classes.listIcon} /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Start Work' secondary='Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt. consectetur adipiscing elit, sed do eiusmod tempor incididunt' />
                                </ListItem>
                        </List>
                    </Grid>             
                </Grid>
            </Box>

         </Container>

            {/* TOP COMPANIES */}
            <Box className={`${classes.section}`} bgcolor='#faa83145' padding='5.5rem 0'>
            <Container>
                <Typography variant='h3' className={classes.title}>Top Company <span>Openings</span></Typography>
                <Typography paragraph color='textSecondary' style={{textAlign: 'center', margin: '1rem 0 3rem 0'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</Typography>

                    <Grid container spacing={3}>
                        { props.allJobs && props.allJobs.map(item => {
                            return (
                                <Grid item xs={12} sm={6} md={4}  key={item.id}>
                                    <Card className={classes.company}>
                                        <Box display={'flex'} marginBottom={'1rem'} gridGap={'.5rem'}>
                                            <Avatar className={classes.companyIcon}> {item.company.companyName.charAt(0)} </Avatar>
                                            <span style={{width: '80%'}}>
                                                <Typography variant='h6' noWrap>{item.title}</Typography>
                                                <Typography variant='body2' color='textSecondary' style={{marginBottom: '.3rem'}}>{item.company.companyName}</Typography>
                                                <Rating name="read-only" value={4} size='small' readOnly />
                                            </span>

                                        </Box>
                                        <Box>
                                            <Typography paragraph color='textSecondary' className={classes.truncate}>{item.description}</Typography>
                                            <Button href={`/joblists/${item.slug}`} disableElevation color='primary' endIcon={<ArrowForward />}>Apply Now</Button>
                                        </Box>
                                    </Card>
                                </Grid>
                            )
                        })
                        }
                    </Grid>
                    
                    <Box textAlign={'center'}>
                        <Button variant='contained' href='/joblists' size='large' disableElevation color='primary' style={{ marginTop: '3rem'}}>Discover More</Button>
                    </Box>

            </Container>
            </Box>

            <Container >
                <Box className={classes.section}>
                    <Typography variant='h3' className={classes.title}>Get Latest <span>Openings</span></Typography>
                    <Typography paragraph color='textSecondary' style={{textAlign: 'center', margin: '.6rem 0 1rem 0'}}>Dont miss out on any opening. Subscribe with us to stay up-to-date</Typography>

                        <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                            <Grid item sm={7}>
                                <TextField className={classes.search} placeholder='Enter your email..' variant='outlined' fullWidth InputProps={{
                                    endAdornment: <InputAdornment position='end'>
                                        <Button size='large' variant='contained' disableElevation color='primary' className={classes.btn}>Subscribe</Button> 
                                        </InputAdornment>,
                                    startAdornment: <InputAdornment position='start'> <Email className={classes.searchIcon} /> </InputAdornment>
                                }} />

                            </Grid>
                        </Grid>
                </Box>
            </Container>

        
    </>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {})(Home)
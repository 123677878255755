import axios from "axios";

export const authRequest = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/auth/request");
      //console.log(res.data)
      if (res.data.status === "user found") {
        dispatch({ type: "NEW_USER", payload: res.data.data });
        dispatch({ type: "EXIT_LOADING" });
      }
      if (res.data.status === "no user found") {
        dispatch({ type: "EXIT_LOADING" });
        dispatch({ type: "NO_USER" });
      }
    } catch (error) {
      console.log(error.response);
    }
  };
};

//WAITLIST
export const waitList = (email) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/v1/waitlist", email);
      //console.log(res.data.data)
      return res.data;
    } catch (error) {
      //console.log(error.response);
      return error.response.data;
    }
  };
};

export const signupUser = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/auth/signup", data);
      dispatch({ type: "NEW_USER", payload: res.data.data });
      console.log(res.data.data);
      return res.data;
    } catch (error) {
      //console.log(error.response);
      return error.response.data;

      // else {
      //     dispatch(errorModal(`Sorry, something went wrong. Please try again`))
      // }
    }
  };
};

// VERIFY EMAIL
export const verifyEmail = (token) => {
  return async (dispatch) => {
    try {
      dispatch(successModal("Verifying your account. Please wait"));

      const res = await axios.patch(`/auth/verifyEmail/${token}`);
      if (res.data.status === "success") {
        dispatch(successModal("Verification successful. Please wait.."));
        setTimeout(() => {
          dispatch({ type: "LOGIN_USER", payload: res.data.data });
        }, 1500);
      }
    } catch (error) {
      if (error.response.data.status === "failed") {
        dispatch(errorModal(error.response.data.message));
      }
      console.log(error.response);
    }
  };
};

// RESEND EMAIL VERIFICATION
export const resendEmail = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/auth/resendEmail`);
      if (res.data.status === "success") {
        dispatch(
          successModal("Verification email sent. Please check your inbox")
        );
      }
    } catch (error) {
      console.log(error.response);
      dispatch(errorModal("Sorry, could not send email. Please try again"));
    }
  };
};

//FORGOT PASSWORD
export const forgortUserPassword = (email) => {
  return async (dispatch) => {
    try {
      console.log(email);
      const res = await axios.post("/auth/forgotpassword", { email });
      if (res.data.status === "success") {
        dispatch(
          successModal("A password reset link has been sent to your email")
        );
        setTimeout(() => {
          window.location.assign("/reset-password");
        }, 1500);
      }
    } catch (error) {
      console.log(error.response);
      dispatch(errorModal(error.response.data.message));
    }
  };
};

//RESET PASSWORD
export const resetUserPassword = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch(`/auth/resetpassword`, data);
      if (res.data.status === "success") {
        dispatch(successModal("Password reset successful. Please login"));
        setTimeout(() => {
          window.location.assign("/login");
        }, 1500);
      }
    } catch (error) {
      console.log(error);
      dispatch(errorModal(error.response.data.message));
    }
  };
};

//LOGIN USER
export const loginUser = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/auth/login", data);
      dispatch({ type: "NEW_USER", payload: res.data.data });
      return res.data;
    } catch (error) {
      console.log(error.response.data);
      return error.response.data;
    }
  };
};

// LOGOUT USER
export const logoutUser = () => {
  return async (dispatch) => {
    try {
      const res = await axios.delete("/auth/logout");
      if (res.data.status === "success") {
        dispatch({ type: "LOGOUT_USER" });
        window.location.assign("/");
      }
    } catch (error) {
      console.log(error.response);
    }
  };
};

//USER PROFILE UPDATES
export const editUserProfile = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/u/account/profile", data);
      dispatch({ type: "NEW_USER", payload: res.data.data });
      return res.data;
    } catch (error) {
      console.log(error.response.data);
      return error.response.data;
    }
  };
};

//USER PROFILE DELETES
export const deleteUserProfile = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch("/u/account/profile", data);
      dispatch({ type: "NEW_USER", payload: res.data.data });
      return res.data;
    } catch (error) {
      console.log(error.response.data);
      return error.response.data;
    }
  };
};

//USER PROFILE PHOTO
export const uploadUserPhoto = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch("/u/account/photo", data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      dispatch({ type: "NEW_USER", payload: res.data.data });
      return res.data;
    } catch (error) {
      console.log(error.response.data);
      return error.response.data;
    }
  };
};

//USER PROFILE COVERPHOTO
export const uploadUserCoverImg = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch("/u/account/coverImg", data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      dispatch({ type: "NEW_USER", payload: res.data.data });
      return res.data;
    } catch (error) {
      console.log(error.response.data);
      return error.response.data;
    }
  };
};

//USER RESUME
export const uploadUserResume = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch("/u/account/resume", data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      dispatch({ type: "NEW_USER", payload: res.data.data });
      return res.data;
    } catch (error) {
      console.log(error.response.data);
      return error.response.data;
    }
  };
};

//USER SETTINGS UPDATES
export const editUserSettings = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/u/account/settings", data);
      dispatch({ type: "NEW_USER", payload: res.data.data });
      return res.data;
    } catch (error) {
      //console.log(error.response.data)
      return error.response.data;
    }
  };
};

// --------- JOBS --------- ///

export const paymentPlan = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch("/api/company/account/plans", data);
      dispatch({ type: "NEW_USER", payload: res.data.data });
      return res.data;
    } catch (error) {
      //console.log(error.response.data)
      return error.response.data;
    }
  };
};

export const newJob = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/company/jobs/new", data);
      dispatch({ type: "COMPANY_JOBS", payload: res.data.data });
      return res.data;
    } catch (error) {
      //console.log(error.response.data)
      return error.response.data;
    }
  };
};

export const jobApplication = (data) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/company/jobs/apply", data);
      dispatch({ type: "NEW_USER", payload: res.data.data });
      return res.data;
    } catch (error) {
      console.log(error.response.data);
      return error.response.data;
    }
  };
};

export const getAllJobs = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/all/jobs");
      dispatch({ type: "ALL_JOBS", payload: res.data.data });
      return res.data;
    } catch (error) {
      console.log(error.response.data);
      return error.response.data;
    }
  };
};

export const getCompanyJobs = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/api/company/jobs");
      dispatch({ type: "COMPANY_JOBS", payload: res.data.data });
      return res.data;
    } catch (error) {
      //console.log(error.response.data)
      return error.response.data;
    }
  };
};

export const markFavourite = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch(`/api/jobs/favourite/${id}`);
      dispatch({ type: "NEW_USER", payload: res.data.data });
      return res.data;
    } catch (error) {
      //console.log(error.response.data)
      return error.response.data;
    }
  };
};

export const unmarkFavourite = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`/api/jobs/favourite/${id}`);
      dispatch({ type: "NEW_USER", payload: res.data.data });
      return res.data;
    } catch (error) {
      //console.log(error.response.data)
      return error.response.data;
    }
  };
};

export const editJobStatus = (id, val) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch(`/api/jobs/${id}/${val}`);
      dispatch({ type: "COMPANY_JOBS", payload: res.data.data });
      return res.data;
    } catch (error) {
      //console.log(error.response.data)
      return error.response.data;
    }
  };
};

export const deleteJob = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(`/api/jobs/${id}/delete`);
      dispatch({ type: "COMPANY_JOBS", payload: res.data.data });
      return res.data;
    } catch (error) {
      //console.log(error.response.data)
      return error.response.data;
    }
  };
};

export const jobViews = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch(`/api/all/jobs/${id}`);
      return res.data;
    } catch (error) {
      //console.log(error.response.data)
      return error.response.data;
    }
  };
};

// NOTIFICATIONS ACTIONS
export const userNotifications = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/api/user/notifications`);
      //console.log(res.data)
      dispatch({ type: "USER_NOTIFICATIONS", payload: res.data.data });
    } catch (error) {
      console.log(error.response);
    }
  };
};

export const companyNotifications = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/api/company/notifications`);
      //console.log(res.data)
      dispatch({ type: "USER_NOTIFICATIONS", payload: res.data.data });
    } catch (error) {
      console.log(error.response);
    }
  };
};

export const readNotification = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch(`/api/web/v1/notification/${id}`);
      //console.log(res.data)
      dispatch({ type: "USER_NOTIFICATIONS", payload: res.data.data });
    } catch (error) {
      console.log(error.response);
    }
  };
};

// MODAL - ERROR ACTIONS CREATORS //

export const successModal = (message) => {
  return {
    type: "SUCCESS_MODAL",
    payload: message,
  };
};

export const loadingModal = (message) => {
  return {
    type: "LOADING_MODAL",
    payload: message,
  };
};

export const errorModal = (message) => {
  return {
    type: "ERROR_MODAL",
    payload: message,
  };
};

export const resetModal = () => {
  return {
    type: "RESET_MODAL",
  };
};

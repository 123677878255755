import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  Email,
  KeyboardArrowLeft,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useReducer, useState } from "react";
import { connect } from "react-redux";
import "animate.css";
import Modal from "../components/Modal";
import { loginUser, errorModal, successModal } from "../actions/actions";
import Student from "../assets/student.png";
import Company from "../assets/company.png";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "8rem",
    marginBottom: "8rem",
    "& .MuiDivider-root": {
      flexGrow: 1,
    },
  },
  wrapper: {
    [theme.breakpoints.up("sm")]: {
      margin: "2rem 0 4rem 0rem",
    },
    display: "flex",
    flexDirection: "column",
    padding: "3.2rem",
    borderRadius: "16px",
    boxShadow: "-24px 24px 72px 8px rgb(145 158 171 / 24%)",
    "& a": {
      color: theme.primaryColor,
      fontWeight: 400,
    },
  },
  wrap: {
    padding: "6rem 3rem",
    border: `1px solid ${grey[400]}`,
    textAlign: "center",
    borderRadius: "10px",
    transition: "all .3s ease",
    "&:hover": {
      border: `2px solid ${theme.secondaryColor}`,
      cursor: "pointer",
    },
  },
  title: {
    fontSize: "1.6rem",
    marginBottom: ".5rem",
    fontWeight: 500,
  },
  field: {
    marginBottom: "1.5rem",
    borderRadius: "1.6rem",
    "& *": {
      borderRadius: "8px",
    },
    "& label.Mui-focused": {
      color: theme.primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColor}`,
      },
    },
  },
  btn: {
    background: theme.primaryColor,
    padding: "1rem 0",
    color: "#fff",
    borderRadius: "8px",
    marginBottom: "2rem",
    "&:hover": {
      background: theme.secondaryColor,
    },
  },
  flex: {
    marginBottom: "2rem",
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
  },
  fieldIcon: {
    color: grey[400],
    cursor: "pointer",
  },
  hold: {
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState("all");
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(false);
  //console.log(path)

  const studentFn = (state, action) => {
    switch (action.type) {
      case "PASSWORD":
        return { ...state, password: action.payload.trim() };
      case "EMAIL":
        return { ...state, email: action.payload.trim().toLowerCase() };
      case "RESET":
        return { password: "", email: "" };
      default:
        return state;
    }
  };

  const companyFn = (state, action) => {
    switch (action.type) {
      case "COMPANY_PASSWORD":
        return { ...state, password: action.payload.trim() };
      case "COMPANY_EMAIL":
        return { ...state, email: action.payload.trim().toLowerCase() };
      case "RESET":
        return { password: "", email: "" };
      default:
        return state;
    }
  };

  const [formInput, dispatch] = useReducer(studentFn, {
    password: "",
    email: "",
  });
  const [companyInput, compDispatch] = useReducer(companyFn, {
    password: "",
    email: "",
  });

  const onFormSubmit = (e) => {
    if (page === "Student") {
      e.preventDefault();
      if (formInput.email === "" || !formInput.email.includes("@")) {
        props.errorModal("Please provide a valid email");
        return;
      }
      if (formInput.password === "" || formInput.password.length < 8) {
        props.errorModal("Sorry, password must be more than 8 characters");
        return;
      }

      const data = { ...formInput, type: page };
      setDisable(true);

      // call action creators
      setTimeout(async () => {
        const res = await props.loginUser(data);
        if (res.status === "success") {
          props.successModal("Login successful");
        } else {
          setDisable(false);
          props.errorModal(res.message);
        }
      }, 1000);
    }

    if (page === "Company") {
      e.preventDefault();
      if (companyInput.email === "" || !companyInput.email.includes("@")) {
        props.errorModal("Please provide a valid email");
        return;
      }
      if (companyInput.password === "" || companyInput.password.length < 8) {
        props.errorModal("Sorry, password must be more than 8 characters");
        return;
      }

      const data = { ...companyInput, type: page };
      setDisable(true);

      // call action creators
      setTimeout(async () => {
        const res = await props.loginUser(data);
        if (res.status === "success") {
          props.successModal("Login successful");
        } else {
          setDisable(false);
          props.errorModal(res.message);
        }
      }, 1000);
    }
  };

  const goBack = () => {
    dispatch({ type: "RESET" });
    compDispatch({ type: "RESET" });
    setPage("all");
  };

  return (
    <div>
      <Container className={classes.root}>
        {/* MODAL  */}
        {props.modal && <Modal status={props.modal.status} />}

        <Box
          className={classes.hold}
          margin="0 auto"
          height={"fit-content"}
          display={page === "all" ? "flex" : "none"}
          justifyContent="center"
          flexDirection={"column"}
        >
          <Box textAlign={"center"} marginBottom="3rem">
            <Typography className={classes.title} variant="h5">
              {" "}
              Hello Again.{" "}
            </Typography>
            <Typography color="textSecondary">
              Choose a portal to login to your account
            </Typography>
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box className={classes.wrap} onClick={() => setPage("Student")}>
                <img
                  src={Student}
                  width="40%"
                  alt={"students"}
                  style={{ opacity: 0.2, marginBottom: "1rem" }}
                />
                <Typography variant="h5" gutterBottom>
                  Student
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Login as a student. Create a unique career profile with
                  Internse
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box className={classes.wrap} onClick={() => setPage("Company")}>
                <img
                  src={Company}
                  width="40%"
                  alt={"company"}
                  style={{ opacity: 0.2, marginBottom: "1rem" }}
                />
                <Typography variant="h5" gutterBottom>
                  Company
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Login as a Company. Get quality Candidates and hire the best.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid
          container
          style={{
            marginTop: "4rem",
            display: page === "Student" || page === "Company" ? "flex" : "none",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={5}>
            <Box>
              <Button
                variant="text"
                startIcon={<KeyboardArrowLeft />}
                style={{ marginBottom: "1rem" }}
                onClick={goBack}
              >
                Go Back
              </Button>
            </Box>
            <div className={`${classes.wrapper}`}>
              <Typography className={classes.title} variant="h5">
                {page} Login
              </Typography>
              <Typography
                style={{ marginBottom: "2.5rem" }}
                paragraph
                color="textSecondary"
              >
                Don't have an account?{" "}
                <Link href="/register">Create Account</Link>
              </Typography>
              {page === "Student" && (
                <form onSubmit={(e) => onFormSubmit(e)}>
                  <TextField
                    className={classes.field}
                    variant="outlined"
                    label="Email"
                    helperText=""
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.fieldIcon} />
                        </InputAdornment>
                      ),
                    }}
                    value={formInput.email}
                    onChange={(e) =>
                      dispatch({ type: "EMAIL", payload: e.target.value })
                    }
                    fullWidth
                  />
                  <TextField
                    className={classes.field}
                    variant="outlined"
                    label="Password"
                    helperText=""
                    fullWidth
                    type={show ? "text" : "password"}
                    value={formInput.password}
                    onChange={(e) =>
                      dispatch({ type: "PASSWORD", payload: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {show ? (
                            <Visibility
                              onClick={() => setShow(!show)}
                              className={classes.fieldIcon}
                            />
                          ) : (
                            <VisibilityOff
                              onClick={() => setShow(!show)}
                              className={classes.fieldIcon}
                            />
                          )}{" "}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    className={classes.btn}
                    disabled={disable}
                    variant="contained"
                    disableElevation
                    type="submit"
                    fullWidth
                  >
                    Login
                  </Button>
                </form>
              )}

              {page === "Company" && (
                <form onSubmit={(e) => onFormSubmit(e)}>
                  <TextField
                    className={classes.field}
                    variant="outlined"
                    label="Email"
                    helperText=""
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.fieldIcon} />
                        </InputAdornment>
                      ),
                    }}
                    value={companyInput.email}
                    onChange={(e) =>
                      compDispatch({
                        type: "COMPANY_EMAIL",
                        payload: e.target.value,
                      })
                    }
                    fullWidth
                  />
                  <TextField
                    className={classes.field}
                    variant="outlined"
                    label="Password"
                    helperText=""
                    fullWidth
                    type="password"
                    value={companyInput.password}
                    onChange={(e) =>
                      compDispatch({
                        type: "COMPANY_PASSWORD",
                        payload: e.target.value,
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {" "}
                          <Visibility className={classes.fieldIcon} />{" "}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    className={classes.btn}
                    disabled={disable}
                    variant="contained"
                    disableElevation
                    type="submit"
                    fullWidth
                  >
                    Login
                  </Button>
                </form>
              )}

              <Typography style={{ textAlign: "center" }}>
                {" "}
                <Link href="/forgot-password" underline="always">
                  Forgot Password{" "}
                </Link>{" "}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {
  loginUser,
  errorModal,
  successModal,
})(Login);

import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import Applications from './Applications'
import JobListing from './JobListing'
import PostAJob from './PostAJob'
import Settings from './Settings'
import SingleApplication from './SingleApplication'

const useStyles = makeStyles(theme => ({
  root : {
    padding: '3rem 0',
    background: 'whitesmoke',
    height: 'fit-content'
  }
}))

const Company = (props) => {
  
  const { jobs } = props
  const classes = useStyles()
  const path = useLocation().pathname
  const title = path.split('/')[3]
  const [currentJob, setCurrentJob ] = useState(null)

  useEffect(()=>{
    if(jobs && jobs.length > 0 ){
      const res = jobs.filter(el => el.slug === title)
      setCurrentJob(res[0])
    }
    
},[title, jobs])

  const renderPage = () => {

    if(props.currentUser && props.currentUser.isCompany){
      return (
        <>
          { path === '/company/listing' && <JobListing /> }
          { path === '/company/new-listing' && <PostAJob /> }
          { path === '/company/settings' && <Settings /> }
          { path === '/company/applications' && <Applications /> }
          { path === `/company/applications/${title}` && <SingleApplication job={currentJob} /> }
        </>
      )
    }

    return <Navigate to='/' />
  }
  return (
    <div className={classes.root}>
      {
        renderPage()
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {})(Company)
import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Email } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { errorModal, successModal } from "../actions/actions";
import { forgortUserPassword } from "../actions/actions";
import Modal from "../components/Modal";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "8rem",
    marginBottom: "8rem",
    "& .MuiDivider-root": {
      flexGrow: 1,
    },
  },
  wrapper: {
    [theme.breakpoints.up("sm")]: {
      margin: "2rem 0 4rem 0rem",
    },
    display: "flex",
    flexDirection: "column",
    padding: "3.2rem",
    borderRadius: "16px",
    boxShadow: "-24px 24px 72px 8px rgb(145 158 171 / 24%)",
    "& a": {
      color: theme.primaryColor,
      fontWeight: 400,
    },
  },
  wrap: {
    padding: "6rem 3rem",
    border: `1px solid ${grey[400]}`,
    textAlign: "center",
    borderRadius: "10px",
    transition: "all .3s ease",
    "&:hover": {
      border: `2px solid ${theme.secondaryColor}`,
      cursor: "pointer",
    },
  },
  title: {
    fontSize: "1.6rem",
    marginBottom: ".5rem",
    fontWeight: 500,
  },
  hold: {
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  field: {
    marginBottom: "1.5rem",
    borderRadius: "1.6rem",
    "& *": {
      borderRadius: "8px",
    },
    "& label.Mui-focused": {
      color: theme.primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColor}`,
      },
    },
  },
  fieldIcon: {
    color: grey[400],
    cursor: "pointer",
  },
  btn: {
    background: theme.primaryColor,
    padding: "1rem 0",
    color: "#fff",
    borderRadius: "8px",
    marginBottom: "2rem",
    "&:hover": {
      background: theme.secondaryColor,
    },
  },
}));

const ForgotPassword = (props) => {
  const { forgortUserPassword } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [disable, setDisable] = useState(false);

  const onFormSubmit = async () => {
    if (email === "" || !email.includes("@"))
      return dispatch(
        errorModal("Invalid. Please provide a valid email address")
      );

    try {
      setDisable(true);
      await forgortUserPassword(email);
    } catch (error) {
      setDisable(false);
      console.log(error?.response);
    }
  };

  return (
    <Box>
      {/* MODAL  */}
      {props.modal && <Modal status={props.modal.status} />}
      <Container maxWidth={"sm"} className={classes.root}>
        <div className={`${classes.wrapper}`}>
          <Typography className={classes.title} variant="h5">
            Forgot Password
          </Typography>
          <Typography
            style={{ marginBottom: "2.5rem" }}
            paragraph
            color="textSecondary"
          >
            Please provide your email address to reset password
          </Typography>
          <TextField
            className={classes.field}
            variant="outlined"
            label="Email"
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Email className={classes.fieldIcon} />
                </InputAdornment>
              ),
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />

          <Button
            className={classes.btn}
            disabled={disable}
            variant="contained"
            disableElevation
            type="submit"
            fullWidth
            onClick={onFormSubmit}
          >
            Send Reset Code
          </Button>
        </div>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { forgortUserPassword })(
  ForgotPassword
);

import { Box, Dialog, LinearProgress, Typography } from '@material-ui/core'
import React from 'react'

const SaveBox = (props) => {
    const { image, open} = props

  return (
    <div>
        <Dialog open={open} >
            <Box padding={'2.5rem'} textAlign={'center'}>
                <img src={image} alt='update' width={'25%'}/>
                <Typography paragraph>{props.text}</Typography>
                <LinearProgress variant='indeterminate' />
            </Box>
        </Dialog>
    </div>
  )
}

export default SaveBox
import { Box, Button, Container,Link, Grid, InputAdornment, TextField, Typography, MenuItem } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Business, Email, KeyboardArrowLeft, Language, LocationOn, Person, Phone, Visibility, VisibilityOff } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useReducer, useState } from 'react'
import Modal from '../components/Modal'
import { errorModal, successModal, signupUser } from '../actions/actions'
import { connect } from 'react-redux'
import Student from '../assets/student.png'
import Company from '../assets/company.png'


const useStyles = makeStyles(theme => ({
  root : {
    marginTop: '8rem',
    marginBottom: '8rem',
    '& .MuiDivider-root': {
      flexGrow: 1
    }
  },
  wrapper : {
    [theme.breakpoints.up('sm')] : {
      margin : '2rem 0 4rem 0rem'
    },
    display: 'flex',
    flexDirection: 'column',
    padding: '3.2rem',
    borderRadius: '16px',
    boxShadow: '-24px 24px 72px 8px rgb(145 158 171 / 24%)',
    '& a': {
      color: theme.primaryColor,
      fontWeight: 400
      
    }
  },
  wrap: {
    padding: '6rem 3rem',
    border: `1px solid ${grey[400]}`,
    textAlign: 'center',
    borderRadius: '10px',
    transition: 'all .3s ease',
    '&:hover': {
      border: `2px solid ${theme.secondaryColor}`,
      cursor: 'pointer'
    }
  },
  title : {
    fontSize: '1.6rem',
    marginBottom: '.5rem',
    fontWeight: 500
  },
  field : {
    marginBottom: '1.5rem',
    borderRadius: '1.6rem',
    '& *': {
      borderRadius: '8px'
    },
    '& label.Mui-focused':{
      color: theme.primaryColor
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColor}`
      }
    }
  },
  btn: {
    background: theme.primaryColor,
    padding: '1rem 0',
    color: '#fff',
    borderRadius: '8px',
    marginBottom: '2rem',
    '&:hover' : {
      background: theme.secondaryColor
    }
  },
  flex : {
    marginBottom: '2rem',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fieldIcon: {
    color: grey[400],
    cursor: 'pointer',
  },
  hold: {
    width: '60%',
    [theme.breakpoints.down('md')]:{
      width: '80%'
    },
    [theme.breakpoints.down('sm')]:{
      width: '40%'
    },
    [theme.breakpoints.down('xs')]:{
      width: '80%'
    },
  }

}))

const Register = (props) => {
  const classes = useStyles()
  const [page, setPage] = useState('all')
  const [pageRegister, setPageRegister] = useState(0)
  const [show, setShow] = useState(false)
  const [lock, setLock] = useState(false)

  const industries = [
    'Advertising, Media & Communications',
    'Agriculture, Fishing & Forestry',
    'Automotive & Aviation', 
    'Banking, Finance & Insurance', 
    'Construction',
    'Education',
    'Energy & Utilities',
    'Enforcement & Security',
    'Entertainment, Events & Sport',
    'Hospitality & Hotel',
    'I.T & Software Development',
    'Law & Compliance',
    'Mining, Energy & Metals',
    'Real Estate'
  ]

const userState = {firstname: '', lastname: '', password: '', email : '', phone: ''}

  const studentFn = (state, action) => {
    switch (action.type) {
      case "FNAME":
        return {...state, firstname: action.payload.trim()}
      case "LNAME":
        return {...state, lastname: action.payload.trim()}
      case "PHONE":
        return {...state, phone: action.payload}
      case "PASSWORD":
        return {...state, password: action.payload}
      case "EMAIL":
        return {...state, email: action.payload.trim().toLowerCase()}
      case "RESET":
        return userState   
      default:
        return state
    }
  }

  const companyState = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    password: '',
    companyName : '',
    address: '',
    industry: '',
    website: '',
    employees: ''
  }

  const companyFn = (state, action) => {
    switch (action.type) {
      case "COMPANY_FNAME":
        return {...state, firstname: action.payload.trim()}
      case "COMPANY_LNAME":
        return {...state, lastname: action.payload.trim()}
      case "COMPANY_PHONE":
        return {...state, phone: action.payload}
      case "COMPANY_PASSWORD":
        return {...state, password: action.payload}
      case "COMPANY_EMAIL":
        return {...state, email: action.payload.trim()}
      case "COMPANY_INDUSTRY":
        return {...state, industry: action.payload.trim()}
      case "COMPANY_NAME":
        return {...state, companyName: action.payload.trim()}
      case "COMPANY_ADDRESS":
        return {...state, address: action.payload.trim()}
      case "COMPANY_WEBSITE":
        return {...state, website: action.payload.trim()}
      case "COMPANY_EMPLOYEES":
        return {...state, employees: action.payload.trim()}
      case "RESET":
        return companyState   
      default:
        return state
    }
  }

  const [formInput, dispatch] = useReducer(studentFn, userState)
  const [companyInput, compDispatch] = useReducer(companyFn, companyState)

  const goBack = () => {
      dispatch({type:"RESET"})
      compDispatch({type:"RESET"})
      setPage('all')
  }

  const stepForward = () => {
    if(companyInput.firstname === '' || companyInput.lastname === ''){
      props.errorModal('Please provide your name')
      return
    }if(companyInput.email === '' || !companyInput.email.includes('@')){
      props.errorModal('Please provide a valid email')
      return
    }if(companyInput.password === '' || companyInput.password.length < 8){
      props.errorModal('Sorry, password must be more than 8 characters')
      return
    }if(companyInput.phone === '' || companyInput.phone.length > 10 || companyInput.phone.length < 10){
      props.errorModal('Sorry, invalid phone number')
      return
    }

    setPageRegister(1)
  }
  
  const onFormSubmit = () => {
   if(page === 'Student'){
      if(formInput.firstname === '' || formInput.lastname === ''){
        props.errorModal('Please provide your name')
        return
      }if(formInput.email === '' || !formInput.email.includes('@')){
        props.errorModal('Please provide a valid email')
        return
      }if(formInput.password === '' || formInput.password.length < 8){
        props.errorModal('Sorry, password must be more than 8 characters')
        return
      }if(formInput.phone === '' || formInput.phone.length > 10 || formInput.phone.length < 10){
        props.errorModal('Sorry, invalid phone number')
        return
      }
  
      const data ={
        ...formInput,
        type: page
      }
  
      // call action creators
      props.successModal('Creating your account...')
      //console.log(data)
      setLock(true)
      setTimeout( async()=> {
        const res = await props.signupUser(data)
        //console.log(res)
          if(res.status === 'success'){
            dispatch({type: 'RESET'})
            props.successModal('Registration successful.')
            window.location.assign('/verify')
          }
          else{
            setLock(false)
            if(res.status === 'failed' && res.error.code === 11000) {
              const errMessage = `Sorry, ${Object.keys(res.error.keyValue)[0]} is already taken.`
              props.errorModal(errMessage)
            }
            if(res.status === 'failed' && res.error.name === "ValidationError") {
                const errMessage = res.message.split(':')[2].trim();
                props.errorModal(errMessage)
            }
          }
  
      }, 1500)
   }

   if(page === 'Company'){
      if(companyInput.address === '' ){
        props.errorModal('Please provide your company address')
        return
      }if(companyInput.employees === '' ){
        props.errorModal('Please provide number of employees')
        return
      }if(companyInput.industry === '' ){
        props.errorModal('Please select an industry')
        return
      }

      const data ={
        ...companyInput,
        type: page
      }
      console.log(data)
      // call action creators
      props.successModal('Creating your account. Please wait...')
      setLock(true)
      setTimeout( async()=> {
        const res = await props.signupUser(data)
        //console.log(res)
          if(res.status === 'success'){
            dispatch({type: 'RESET'})
            props.successModal('Registration successful.')
            window.location.assign('/verify')
          }
          else{
            setLock(false)
            if(res.status === 'failed' && res.error.code === 11000) {
              const errMessage = `Sorry, ${Object.keys(res.error.keyValue)[0]} is already taken.`
              props.errorModal(errMessage)
            }
            if(res.status === 'failed' && res.error.name === "ValidationError") {
                const errMessage = res.message.split(':')[2].trim();
                props.errorModal(errMessage)
            }
            if(res.status === 'failed'){
              props.errorModal(res.message)
            }
          }
  
       }, 1500)

   }

  }


  return (
    <>
        <Container className={classes.root}>
          {/* MODAL  */}
      { props.modal && <Modal status={props.modal.status} />}

      <Box className={classes.hold} margin='0 auto' height={'fit-content'} display={ page === 'all' ? 'flex' : 'none' } justifyContent='center' flexDirection={'column'}>
      <Box textAlign={'center'} marginBottom='3rem'>
          <Typography className={classes.title} variant='h5'> Signup </Typography>
          <Typography color='textSecondary'>Choose a portal to create an account</Typography>
        </Box>
        <Grid container spacing={4}>

            <Grid item sm={6} >
              <Box className={classes.wrap} onClick={()=> setPage('Student')}>
                <img src={Student} width='40%' alt={'students'} style={{opacity: 0.2, marginBottom: '1rem'}} />
                <Typography variant='h5' gutterBottom>Student</Typography>
                <Typography variant='body1' color='textSecondary'>Looking for Internship? Create a unique career profile with Internse</Typography>
              </Box>
            </Grid>

            <Grid item sm={6} >
              <Box className={classes.wrap} onClick={()=> setPage('Company')}>
                <img src={Company} width='40%' alt={'company'} style={{opacity: 0.2, marginBottom: '1rem'}} />
                <Typography variant='h5' gutterBottom>Company</Typography>
                <Typography variant='body1' color='textSecondary'>Loooking for quality Candidates. Register and search with Internse</Typography>
              </Box>
            </Grid>

        </Grid>
      </Box>

          <Grid container style={{marginTop: '4rem', display: page === 'Student' || page === 'Company' ? 'flex' : 'none', justifyContent: 'center'}}>

            <Grid item xs={12} sm={5} >
              <Box >
                <Button variant='text' startIcon={<KeyboardArrowLeft />} style={{marginBottom: '1rem'}} onClick={goBack}>Go Back</Button>
              </Box>
                <div className={`${classes.wrapper}`}>
                  <Typography className={classes.title} variant='h5'>{page} Account</Typography>
                  <Typography style={{marginBottom: '2.5rem'}} paragraph color='textSecondary'>Already have an account? <Link href='/login'>Login</Link></Typography>
                {
                  page === 'Student' &&
                  <form>
                      <TextField className={classes.field} variant='outlined'  label='Firstname' helperText='' InputProps={{
                          endAdornment: <InputAdornment position='end'><Person className={classes.fieldIcon} /></InputAdornment>
                        }} value={formInput.firstname} onChange={(e)=>dispatch({type: "FNAME", payload: e.target.value})} fullWidth 
                      />
                      <TextField className={classes.field} variant='outlined'  label='Lastname' helperText='' InputProps={{
                          endAdornment: <InputAdornment position='end'><Person className={classes.fieldIcon} /></InputAdornment>
                        }} value={formInput.lastname} onChange={(e)=>dispatch({type: "LNAME", payload: e.target.value})} fullWidth 
                      />
                      <TextField className={classes.field} variant='outlined'  label='Email' helperText='' InputProps={{
                        endAdornment: <InputAdornment position='end'><Email className={classes.fieldIcon} /></InputAdornment>
                      }} value={formInput.email} onChange={(e)=>dispatch({type: "EMAIL", payload: e.target.value})} fullWidth 
                      />
                      <TextField className={classes.field} type={'number'} variant='outlined'  label='Phone' helperText='' InputProps={{
                        endAdornment: <InputAdornment position='end'><Phone className={classes.fieldIcon} /></InputAdornment>
                      }} value={formInput.phone} onChange={(e)=>dispatch({type: "PHONE", payload: e.target.value})} fullWidth 
                      />
                    <TextField className={classes.field} variant='outlined' label='Password' 
                      helperText='' fullWidth type={show ? 'text' : 'password'} value={formInput.password} onChange={(e)=>dispatch({type: "PASSWORD", payload: e.target.value})}
                      InputProps={{ endAdornment: <InputAdornment position='end'>{ show ? <Visibility onClick={()=>setShow(!show)} className={classes.fieldIcon} /> : <VisibilityOff onClick={()=>setShow(!show)} className={classes.fieldIcon} />}  </InputAdornment>}}
                      />
                    <Button className={classes.btn} variant='contained' disabled={lock} disableElevation onClick={()=>onFormSubmit('student')} fullWidth>Register</Button>
                  </form>
                }

                {
                  page === 'Company' &&
                  <form>
                    {
                      pageRegister === 0 && 
                      <>
                          <TextField className={classes.field} variant='outlined'  label='Firstname' helperText='' InputProps={{
                              endAdornment: <InputAdornment position='end'><Person className={classes.fieldIcon} /></InputAdornment>
                            }} value={companyInput.firstname} onChange={(e)=>compDispatch({type: "COMPANY_FNAME", payload: e.target.value})} fullWidth 
                          />
                          <TextField className={classes.field} variant='outlined'  label='Lastname' helperText='' InputProps={{
                              endAdornment: <InputAdornment position='end'><Person className={classes.fieldIcon} /></InputAdornment>
                            }} value={companyInput.lastname} onChange={(e)=>compDispatch({type: "COMPANY_LNAME", payload: e.target.value})} fullWidth 
                          />
                          <TextField className={classes.field} variant='outlined'  label='Email address' type={'email'} InputProps={{
                            endAdornment: <InputAdornment position='end'><Email className={classes.fieldIcon} /></InputAdornment>
                          }} value={companyInput.email} onChange={(e)=>compDispatch({type: "COMPANY_EMAIL", payload: e.target.value})} fullWidth 
                          />
                          <TextField className={classes.field} type={'number'} variant='outlined'  label='Phone' helperText='' InputProps={{
                            endAdornment: <InputAdornment position='end'><Phone className={classes.fieldIcon} /></InputAdornment>
                          }} value={companyInput.phone} onChange={(e)=>compDispatch({type: "COMPANY_PHONE", payload: e.target.value})} fullWidth 
                          />
                        <TextField className={classes.field} variant='outlined' label='Password' 
                          helperText='' fullWidth type={show ? 'text' : 'password'} value={companyInput.password} onChange={(e)=>compDispatch({type: "COMPANY_PASSWORD", payload: e.target.value})}
                          InputProps={{ endAdornment: <InputAdornment position='end'>{ show ? <Visibility onClick={()=>setShow(!show)} className={classes.fieldIcon} /> : <VisibilityOff onClick={()=>setShow(!show)} className={classes.fieldIcon} />}  </InputAdornment>}}
                          />
                          <Button className={classes.btn} disableElevation disabled={lock} onClick={stepForward} fullWidth>Continue</Button>
                      </>
                    }
                    {
                      pageRegister === 1 &&
                      <>
                          <TextField className={classes.field} variant='outlined'  label='Company Name' helperText='' InputProps={{
                              endAdornment: <InputAdornment position='end'><Business className={classes.fieldIcon} /></InputAdornment>
                            }} value={companyInput.companyName} onChange={(e)=>compDispatch({type: "COMPANY_NAME", payload: e.target.value})} fullWidth 
                          />
                          <TextField className={classes.field} select variant='outlined' defaultValue={''} label='Number of Employees' 
                            value={companyInput.employees} onChange={(e)=>compDispatch({type: "COMPANY_EMPLOYEES", payload: e.target.value})} fullWidth 
                          >
                             {
                                ['1-5','5-10','10-20','20-50', '50-100'].map((el, index) => {
                                  return (
                                    <MenuItem key={index} value={el}>{el}</MenuItem>
                                  )
                                })
                             } 
                            </TextField>
                          <TextField className={classes.field} select defaultValue={''} variant='outlined'  label='Industry' 
                            value={companyInput.industry} onChange={(e)=>compDispatch({type: "COMPANY_INDUSTRY", payload: e.target.value})} fullWidth 
                          >
                            {
                                industries.map((el, index) => {
                                  return (
                                    <MenuItem key={index} value={el}>{el}</MenuItem>
                                  )
                                })
                             }
                          </TextField>
                          <TextField className={classes.field} variant='outlined'  label='Address' helperText='' InputProps={{
                              endAdornment: <InputAdornment position='end'><LocationOn className={classes.fieldIcon} /></InputAdornment>
                            }} value={companyInput.address} onChange={(e)=>compDispatch({type: "COMPANY_ADDRESS", payload: e.target.value})} fullWidth 
                          />
                          <TextField className={classes.field} variant='outlined'  label='Website' helperText='' InputProps={{
                              endAdornment: <InputAdornment position='end'><Language className={classes.fieldIcon} /></InputAdornment>
                            }} value={companyInput.website} onChange={(e)=>compDispatch({type: "COMPANY_WEBSITE", payload: e.target.value})} fullWidth 
                          />
                          <Box display={'flex'} gridGap='1rem'>
                            { pageRegister > 0 && <Button variant='contained' className={classes.btn} style={{background: 'lightgrey'}} onClick={()=>setPageRegister(0)} disableElevation fullWidth>Previous</Button> }
                            <Button className={classes.btn} disableElevation disabled={lock} onClick={()=>onFormSubmit()} fullWidth>Register</Button>

                          </Box>
                      </>
                    }
                  </form>
                }
                  
                </div>
            </Grid>

          </Grid>
        </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  console.log(state)
  return state
}

export default connect(mapStateToProps, {errorModal, signupUser, successModal})(Register)
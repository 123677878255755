import { Avatar, Box, Button, Container, Divider, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import {Search} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Listing from '../../assets/listing.png'
import Loader from '../../components/Loader'
import { DataGrid } from '@material-ui/data-grid'

const useStyles = makeStyles(theme => ({
    search : {
        marginBottom: '2rem'
    },
    field: {
        marginBottom: '1rem'
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: 800,
      },
      icon: {
        minWidth: '30px'
      },
      btn: {
        color: '#fff',
        height:'2.8rem',
        borderRadius: '10px',
        marginTop: '1rem'
      }
}))

const Applications = (props) => {
    const { jobs } = props
    const classes = useStyles()
    const [value, setValue] = useState('Published')
    const [filtered, setFiltered] = useState(null)
    const [pageSize, setPageSize] = useState(10)

    useEffect(()=>{
        const filteredJobs = (val) => {
            const res = jobs && jobs.filter(el => el.status === val)
            setFiltered(res)
        }
        filteredJobs(value)

    },[value, jobs])

    const columns = [
        {field: 'title', headerName: 'Job Details', flex: 1, renderCell: (params) => {
            return (
                  <Box display={'flex'} gridGap='1rem'>
                      <Avatar >{params.value.charAt(0).toUpperCase()}</Avatar>
                      <Box width='20rem' >
                          <Typography variant='h6' style={{ fontSize: '1rem' }} noWrap>{params.value}</Typography>
                          <Typography variant='body1' color='textSecondary' noWrap>{params.row.company.companyName}</Typography>
                          <Typography variant='body2' color='textSecondary'>{params.row.jobType}</Typography>
                      </Box>
                  </Box>
                )
            }
        }, 
        {field: 'industry', headerName: 'Category', flex: 1, renderCell: (params) =>  <Typography>{params.value}</Typography> }, 
        {field: 'views', headerName: 'Views', flex: .5, renderCell: (params) => { return <Typography>{params.value} views</Typography> } },
        {field: 'applications', headerName: 'Applications', flex: .5, renderCell: (params) => { return <Typography>{params.value.length}</Typography> } },
        {field: '_id', headerName:' Action', flex: .5, renderCell: (params) => { 
          return (
                <Button variant='contained' size='small' disableElevation color='primary' href={`/company/applications/${params.row.slug}`} style={{textTransform: "none"}}>View </Button>
          )
        }}
      ]

    const renderPage = () => { 
        if(props.jobs){
            if(props.jobs.length > 0 ){
                const publishedJobs = props.jobs.filter(el => el.status === 'Published')
                const draftedJobs = props.jobs.filter(el => el.status === 'Drafted')
                console.log(filtered)
                
                return (
                    <>
                        <Box display={'flex'} marginBottom='1rem'>
                            <Typography variant='h6'>All Applications </Typography>
                            <span style={{marginLeft: 'auto', display: 'flex', }}>
                                <Button size='large' style={{textTransform: 'none'}} onClick={()=> setValue('Published')}>All({publishedJobs.length})</Button> 
                                <Divider orientation='vertical' style={{margin: '0 1rem'}} />
                                <Button size='large' style={{textTransform: 'none'}} onClick={()=> setValue('Drafted')} >ShortListed({draftedJobs.length})</Button>
                            </span>
                        </Box>

                        {/* Searchbar */}
                        <TextField className={classes.search} InputProps={{
                            startAdornment: <InputAdornment position='start'><Search style={{color: grey[400]}} /></InputAdornment>
                        }} size='medium' variant='outlined' fullWidth placeholder='Search for interships' />

                        

                        <Grid container spacing={3}>
                            {
                                filtered && filtered.length > 0 ? 
                                    <Grid item sm={12}>
                                        <DataGrid autoHeight style={{background: '#fff'}}
                                            pagination rows={filtered ? filtered : []} isRowSelectable={false} rowsPerPageOptions={[5, 10, 15, 20]}
                                            rowHeight={100} columns={columns} getRowId={ row => row._id}
                                            pageSize={pageSize} checkboxSelection 
                                            onPageSizeChange={(newSize)=> setPageSize(newSize)}
                                        />
                                    </Grid>
                                :
                                <Grid item sm={12}>
                                    <Box padding={'6rem'} bgcolor={'#fff'} border='1px dashed lightgrey' borderRadius={'10px'} marginBottom={'3rem'}>
                                        <Box textAlign={'center'} marginBottom='2rem'>
                                            <img src={Listing} alt='job-listing' width='20%' style={{margin:'0 auto', marginBottom: '2rem'}} />
                                            <Typography variant='h5' gutterBottom>{`Manage Your ${value} Listing`}</Typography>
                                            <Typography color='textSecondary'>{`You have no ${value} Jobs`}</Typography>
                                        </Box>
                                        
                                        <Button variant='contained' color='primary' size='large' href='/company/new-listing'
                                            style={{margin: '0 auto', marginTop: '2rem', display: 'block', width: 'fit-content'}} disableElevation >Post A Job</Button>
                                    </Box>
                                </Grid>

                            }
                        </Grid>
                    </>
                )
            }else {
                return (
                    <Box padding={'6rem'} bgcolor={'#fff'} border='1px dashed lightgrey' borderRadius={'10px'} marginBottom={'3rem'}>
                        <Box textAlign={'center'} marginBottom='2rem'>
                            <img src={Listing} alt='job-listing' width='20%' style={{margin:'0 auto', marginBottom: '2rem'}} />
                            <Typography variant='h5' gutterBottom>Manage Your Job Listing</Typography>
                            <Typography></Typography>
                        </Box>
                        <Grid container spacing={4}>
                            <Grid item sm={4} style={{display: 'flex', gap: '1rem', alignItems: 'flex-start'}}>
                                <Avatar>1</Avatar>
                                <span>
                                    <Typography style={{fontSize: '1rem'}} variant='h6'>Select a Plan</Typography>
                                    <Typography color='textSecondary'>Choose a plan that best suites your needs to start listing your jobs</Typography>
                                </span>
                            </Grid>
                            <Grid item sm={4} style={{display: 'flex', gap: '1rem',alignItems: 'flex-start' }}>
                                <Avatar>2</Avatar>
                                <span>
                                    <Typography style={{fontSize: '1rem'}} variant='h6'>List Your Job</Typography>
                                    <Typography color='textSecondary'>Choose a plan that best suites your needs to start listing your jobs</Typography>
                                </span>
                            </Grid>
                            <Grid item sm={4} style={{display: 'flex', gap: '1rem', alignItems: 'flex-start'}}>
                                <Avatar>3</Avatar>
                                <span>
                                    <Typography style={{fontSize: '1rem'}} variant='h6'>Receive Leads</Typography>
                                    <Typography color='textSecondary'>Choose a plan that best suites your needs to start listing your jobs</Typography>
                                </span>
                            </Grid>
                        </Grid>
                        <Button variant='contained' color='primary' size='large' href='/company/new-listing'
                            style={{margin: '0 auto', marginTop: '2rem', display: 'block', width: 'fit-content'}} disableElevation >Post A Job</Button>
                     </Box>
                )
            }
        }

        return <Loader />
    }
    
  return (
    <div>
        <Container>
            
            { 
               renderPage()
            }

        </Container>

       
    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {})(Applications)
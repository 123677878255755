import { Avatar, Box, Button, Card, CardContent, Dialog, DialogActions,DialogContent,DialogTitle, Chip, Container, Divider, Grid, IconButton, LinearProgress, Typography, TextField, CircularProgress, InputAdornment } from '@material-ui/core'
import { React, useReducer, useRef, useState } from 'react'
import Cover from '../../assets/cover.jpg'
import { Add, CameraAlt, Edit } from '@material-ui/icons'
import { grey } from '@material-ui/core/colors'
import ExperienceItem from '../../components/ExperienceItem'
import EducationItem from '../../components/EducationItem'
import File from '../../assets/file.png'
import Books from '../../assets/education.png'
import About from '../../assets/profile.png'
import Portfolio from '../../assets/portfolio.png'
import { connect } from 'react-redux'
import Loader from '../../components/Loader'
import { errorModal, editUserProfile, successModal, uploadUserPhoto, uploadUserCoverImg, uploadUserResume, deleteUserProfile } from '../../actions/actions'
import { makeStyles } from '@material-ui/styles'
import Modal from '../../components/Modal'
import ResumeItem from '../../components/ResumeItem'


const useStyles = makeStyles(theme => ({
  root:  {
    paddingTop: '3rem'

  },
  title: {
    fontSize: '1.4rem'
  },
  cover : {
    height: '10rem',
    borderRadius: '15px',
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: '0 auto',
    marginBottom: '.5rem',
    border: '6px solid #fff'
  },
  card : {
    marginTop: '2rem',
    padding: '1rem',
    borderRadius: '15px',
    transition: 'all .2s ease',
    '&:hover': {
        boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)',
        border: `1px solid ${theme.secondaryColor}`
      }
  },
  bar : {
    height: '8px',
    borderRadius: '10px',
    marginBottom: '5px'
  },
  skills : {
    margin : '5px'
  },
  iconBtn: {
    background: grey[200]
  },
  field : {
    marginBottom: '1rem'
  }
}))

const Profile = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(null)
  const [loader, setLoader] = useState(false)
  const browse = useRef()
  const cover = useRef()
  const resume = useRef()
  

  const initState = {
    about: '',
    skills: '',
    languages: ''
  }

  const experience = {
    title: '',
    company: '',
    from: '',
    to: ''
  }

  const education = {
    institution: '',
    degree: '',
    major: '',
    from: '',
    to: ''
  }

  const reducerFn = (state, action) => {
    switch (action.type) {
      case "ABOUT":
        return {...state, about: action.payload}
      case "SKILLS":
        return {...state, skills: action.payload}
      case "LANGUAGES":
        return {...state, languages: action.payload}
      case "RESET":
        return initState
      default:
        break;
    }
  }

  const experienceFn = (state, action) => {
    switch (action.type) {
      case "TITLE":
        return {...state, title: action.payload}
      case "COMPANY":
        return {...state, company: action.payload }
      case "FROM":
        return {...state, from: action.payload }
      case "TO":
        return {...state, to: action.payload }
      case "RESET":
        return experience
      default:
        break;
    }
  }

  const educationFn = (state, action) => {
    switch (action.type) {
      case "INSTITUTION":
        return {...state, institution: action.payload}
      case "MAJOR":
        return {...state, major: action.payload }
      case "DEGREE":
        return {...state, degree: action.payload }
      case "FROM":
        return {...state, from: action.payload }
      case "TO":
        return {...state, to: action.payload }
      case "RESET":
        return education
      default:
        break;
    }
  }

  const [formInput, dispatch] = useReducer(reducerFn, initState)
  const [exInput, exDispatch] = useReducer(experienceFn, experience)
  const [eduInput, eduDispatch] = useReducer(educationFn, education)


  const onHandleOpen = (val) => {
    setOpen(val)
  }

  const onHandleClose = () => {
    setOpen(null)
    dispatch({type: "RESET"})
    exDispatch({type: "RESET"})
    eduDispatch({type: "RESET"})
  }

  const onFormSubmit = (val) => {
    console.log(formInput)

    if(val === 'about'){
      if(formInput.about === '') {
        props.errorModal('Invalid. Please fill all fields')
        return
      }
      const data = { about: formInput.about, field: val}
      onHandleClose()
      setLoader(true)
      setTimeout(async() => {
          const res = await props.editUserProfile(data)
          if(res.status === 'success'){
            setLoader(false)
            dispatch({type: "RESET"})
            props.successModal('User profile updated successfully')
          }else{
            props.errorModal('Sorry, something went wrong. Please try again')
          }
      }, 1000);
    }

    // EXPERIENCE
    if(val === 'experience'){
      if(exInput.title === '' || exInput.company === '' || exInput.from === '' || exInput.to === '') {
        props.errorModal('Invalid. Please fill all fields')
        return
      }
      const data = { experience: exInput, field: val}
      onHandleClose()
      setLoader(true)
      setTimeout(async() => {
          const res = await props.editUserProfile(data)
          if(res.status === 'success'){
            setLoader(false)
            exDispatch({type: "RESET"})
            props.successModal('User profile updated successfully')
          }else{
            props.errorModal('Sorry, something went wrong. Please try again')
          }
      }, 1000);
    }

    // EDUCATION
    if(val === 'education'){
      if(eduInput.institution === '' || eduInput.degree === '' || eduInput.major === '' || eduInput.from === '' || eduInput.to === '') {
        props.errorModal('Invalid. Please fill all fields')
        return
      }
      const data = { education: eduInput, field: val}
      onHandleClose()
      setLoader(true)
      setTimeout(async() => {
          const res = await props.editUserProfile(data)
          if(res.status === 'success'){
            setLoader(false)
            exDispatch({type: "RESET"})
            props.successModal('User profile updated successfully')
          }else{
            props.errorModal('Sorry, something went wrong. Please try again')
          }
      }, 1000);
    }

    //SKILLS
    if(val === 'skills'){
      console.log(formInput)
      if(formInput.skills === ''){
        props.errorModal('Invalid, Please provide an input')
        return
      }
      const data = { skills: formInput.skills.trim(), field : val}
      setLoader(true)
      setTimeout(async() => {
          const res = await props.editUserProfile(data)
          if(res.status === 'success'){
            setLoader(false)
            dispatch({type: "RESET"})
            props.successModal('User profile updated successfully')
          }else{
            props.errorModal('Sorry, something went wrong. Please try again')
          }
      }, 1000);
    }

    //LANGUAGES
    if(val === 'languages'){
      //console.log(formInput)
      if(formInput.languages === ''){
        props.errorModal('Invalid, Please provide an input')
        return
      }
      const data = { languages: formInput.languages.trim(), field : val}
      setLoader(true)
      setTimeout(async() => {
          const res = await props.editUserProfile(data)
          if(res.status === 'success'){
            setLoader(false)
            dispatch({type: "RESET"})
            props.successModal('User profile updated successfully')
          }else{
            props.errorModal('Sorry, something went wrong. Please try again')
          }
      }, 1000);
    }
  }

  const uploadPhoto = (val) => {
    if(val.size > 500000){
      props.errorModal('Invalid. Image size must be less than 500kb')
      return
    }
    const formData = new FormData()
    formData.append('photo', val)

    //call action creator
    setLoader(true)

    setTimeout(async() => {
      const res = await props.uploadUserPhoto(formData)
      if(res.status === 'success'){
        setLoader(false)
        props.successModal('User profile photo updated successful')
      }else{
        setLoader(false)
        props.errorModal('Sorry, something went wrong. Please try again')
      }
    }, 1000);
  }

  const uploadCoverImage = (val) => {
    console.log(val)
    if(val.size > 500000){
      props.errorModal('Invalid. Image size must be less than 500kb')
      return
    }
    const formData = new FormData()
    formData.append('cover', val)

    //call action creator
    setLoader(true)

    setTimeout(async() => {
      const res = await props.uploadUserCoverImg(formData)
      if(res.status === 'success'){
        setLoader(false)
        props.successModal('User cover image updated successful')
      }else{
        setLoader(false)
        props.errorModal('Sorry, something went wrong. Please try again')
      }
    }, 1000);
  }

  const onDelete = (field, value) => {
    const data = { field, value }
    //call action creators
    setLoader(true)

    setTimeout(async() => {
      const res = await props.deleteUserProfile(data)
      if(res.status === 'success'){
        setLoader(false)
        props.successModal('User profile updated successfully')
      }else{
        setLoader(false)
        props.errorModal('Sorry, something went wrong. Please try again')
      }
    }, 1000);
  }

  const uploadResume = (val) => {
    console.log(val)
    if(val.size > 500000){
      props.errorModal('Invalid. Resume size must be less than 500kb')
      return
    }
    const formData = new FormData()
    formData.append('resume', val)

    //call action creator
    setLoader(true)

    setTimeout(async() => {
      const res = await props.uploadUserResume(formData)
      if(res.status === 'success'){
        setLoader(false)
        props.successModal('User resume updated successful')
      }else{
        setLoader(false)
        props.errorModal('Sorry, something went wrong. Please try again')
      }
    }, 1000);
  }


  const renderPage = () => {
    if(props.currentUser){
      return (
        <>
        {props.modal && <Modal status={props.modal.status} />}
          <Container>

              {/* GrID */}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={4}>
                  <Card variant='outlined' elevation={0} className={classes.card}>
                    <Box className={classes.cover} style={{backgroundImage: props.currentUser.photo && props.currentUser.photo.coverImage ? `url(${props.currentUser.photo.coverImage})` : `url(${Cover})`}}>
                      <input type={'file'} accept='.jpg, .png, .jpeg' ref={cover} style={{display: 'none'}} onChange={(e)=> uploadCoverImage(e.target.files[0])} />
                      <IconButton style={{background: '#00000080', color: '#fff'}} onClick={()=> cover.current.click()}><CameraAlt /></IconButton>
                    </Box>
                    <CardContent >
                        <Box textAlign={'center'} marginTop='-3.5rem'>
                          <input type={'file'} accept='.jpg, .png, .jpeg' ref={browse} style={{display: 'none'}} onChange={(e)=> uploadPhoto(e.target.files[0])} />
                          <Avatar className={classes.avatar} src={props.currentUser.photo && props.currentUser.photo.profile ? `${props.currentUser.photo.profile}` : null} />
                          <IconButton onClick={()=> browse.current.click()}
                            style={{marginTop: '-6rem', marginLeft: '6rem',padding: '10px', background: '#faa831', color: '#fff', border: '2px solid white'}}>
                            <Edit fontSize='small' />
                          </IconButton>
                          <span>
                            <Typography variant='h6'>{props.currentUser.fullname}</Typography>
                            <Typography color='textSecondary'>{props.currentUser.email}</Typography>
                            <Typography gutterBottom color='textSecondary'>(+233){props.currentUser.phone}</Typography>
                            <Chip color='primary' label={<Typography color='textSecondary' style={{color: '#fff'}} variant='body2'>Open To Work</Typography>} />
                          </span>
                      </Box>
                    </CardContent>
                  </Card>

                  {/* COMPLETENESS */}
                  <Card variant='outlined' className={classes.card} elevation={0}>
                    <CardContent>
                      <Box display='flex' justifyContent={'space-between'} marginBottom='1rem' alignItems={'center'}>
                        <Typography className={classes.title} variant='h6'>My Profile</Typography>
                        <Chip label={ props.currentUser.isEmailVerified ? 'Active' : 'Inactive'} color='secondary' />
                      </Box>
                      <LinearProgress className={classes.bar} variant='determinate' value={props.currentUser.total} />
                      <Typography variant='body2' color='textSecondary'>{props.currentUser.total}% Complete</Typography>
                    </CardContent>
                  </Card>

                  {/* SKILLS */}
                  <Card variant='outlined' className={classes.card} elevation={0}>
                    <CardContent>
                      <Box display='flex' justifyContent={'space-between'} marginBottom='1rem' alignItems={'center'}>
                        <Typography className={classes.title} variant='h6'>Top Skills</Typography>
                        {/* <IconButton className={classes.iconBtn}><Edit fontSize='small' /></IconButton> */}
                      </Box>
                      {
                        props.currentUser.skills.length > 0 ? 
                        <>
                          <Divider style={{marginBottom: '1rem'}} />
                          <Box>
                            {
                              props.currentUser.skills.map(el => {
                                return  <Chip key={el} className={classes.skills} onDelete={()=>{onDelete('skills',el)}} label={el} />
                              })
                            }
                          
                          </Box>
                        </>
                        : null 
                        }

                        <Box marginTop={'1rem'}>
                          <TextField fullWidth value={formInput.skills} onChange={(e) => dispatch({type: "SKILLS", payload: e.target.value})}
                          variant='outlined' placeholder='Add skills' InputProps={{
                            endAdornment: <InputAdornment position='end'>
                              <Button variant='contained' color='primary' onClick={()=> onFormSubmit('skills')} disableElevation>Add</Button>
                            </InputAdornment>
                          }} />
                        </Box>

                      
                    </CardContent>
                  </Card>

                  {/* LANGUAGE */}
                  <Card variant='outlined' className={classes.card} elevation={0}>
                    <CardContent>
                      <Box display='flex' justifyContent={'space-between'} marginBottom='1rem' alignItems={'center'}>
                        <Typography className={classes.title} variant='h6'>Languages</Typography>
                        {/* <IconButton className={classes.iconBtn}><Edit fontSize='small' /></IconButton> */}
                      </Box>
                      {
                        props.currentUser.languages.length > 0 ? 
                        <>
                          <Divider style={{marginBottom: '1rem'}} />
                          <Box>
                            {
                              props.currentUser.languages.map(el => {
                                return  <Chip key={el} className={classes.skills} onDelete={()=>{onDelete('languages', el)}} label={el} />
                              })
                            }
                          
                          </Box>
                        </>
                        : null 
                        }

                        <Box marginTop={'1rem'}>
                          <TextField fullWidth value={formInput.languages} onChange={(e) => dispatch({type: "LANGUAGES", payload: e.target.value})}
                          variant='outlined' placeholder='Add Language' InputProps={{
                            endAdornment: <InputAdornment position='end'>
                              <Button variant='contained' color='primary' onClick={()=> onFormSubmit('languages')} disableElevation>Add</Button>
                            </InputAdornment>
                          }} />
                        </Box>
                    </CardContent>
                  </Card>

                </Grid>


                <Grid item lg={8} >
                  {/* ABOUT ME */}
                  <Card variant='outlined' elevation={0} className={classes.card}>
                    <CardContent>
                      <Box display='flex' justifyContent={'space-between'} marginBottom='1rem' alignItems={'center'}>
                        <Typography className={classes.title} variant='h6'>About Me</Typography>
                        <IconButton className={classes.iconBtn} onClick={()=> onHandleOpen('about')} ><Edit fontSize='small' /></IconButton>
                      </Box>
                      {
                        props.currentUser && props.currentUser.about ? 
                        <Box>
                          <Divider style={{marginBottom: '2rem'}} />
                          <Typography>
                             {props.currentUser.about}
                          </Typography>
                        </Box>
                        :
                        <Box textAlign={'center'}>
                            <img src={About} alt='resume' width='12%' style={{marginBottom: '10px'}} />
                            <Typography variant='body1' paragraph color='textSecondary'>Let your employers know more about you </Typography>
                            <Button variant='contained' disableElevation onClick={()=> onHandleOpen('about')}  >About Me</Button>
                        </Box>
                      }

                    </CardContent>
                  </Card>
                  
                  {/* EXPERIENCE */}
                  <Card variant='outlined' elevation={0} className={classes.card}>
                    <CardContent>
                      <Box display='flex' justifyContent={'space-between'} marginBottom='1rem' alignItems={'center'}>
                        <Typography className={classes.title} variant='h6'>Experiences</Typography>
                        <IconButton className={classes.iconBtn} onClick={()=> onHandleOpen('experience')}><Add fontSize='small' /></IconButton>
                      </Box>

                      {
                        props.currentUser && props.currentUser.experience.length > 0 ?
                        <Box>
                          <Divider style={{marginBottom: '2rem'}} />
                          {
                            props.currentUser.experience.map((item, index) => {
                              return (
                                <ExperienceItem key={index} company={item.company} position={item.title} startYear={new Date(item.from).getFullYear()} endYear={new Date(item.to).getFullYear()} delete={()=>onDelete('experience', index)} />
                              )
                            })
                          }
                        </Box>
                        :
                        <Box textAlign={'center'}>
                            <img src={Portfolio} alt='resume' width='12%' style={{marginBottom: '10px'}} />
                            <Typography variant='body1' paragraph color='textSecondary'>Tell us about your work experience </Typography>
                            <Button variant='contained' disableElevation  onClick={()=> onHandleOpen('experience')} >Add Experience</Button>
                        </Box>
                        
                      }

                    </CardContent>
                  </Card>

                  {/* EDUCATION */}
                  <Card variant='outlined' elevation={0} className={classes.card}>
                    <CardContent>
                      <Box display='flex' justifyContent={'space-between'} marginBottom='1rem' alignItems={'center'}>
                        <Typography className={classes.title} variant='h6'>Education</Typography>
                        <IconButton className={classes.iconBtn} onClick={()=> onHandleOpen('education')}><Add fontSize='small' /></IconButton>
                      </Box>

                      {
                        props.currentUser && props.currentUser.education.length > 0 ?
                        <Box>
                          <Divider style={{marginBottom: '2rem'}} />
                          {
                            props.currentUser.education.map((item, index) => {
                              return(
                                <EducationItem key={index} institution={item.institution} major={item.major} degree={item.degree} from={new Date(item.from).getFullYear()} to={new Date(item.to).getFullYear()} delete={()=>onDelete('education', index)} />
                              )
                            })
                          }
                        </Box>
                        :
                        <Box textAlign={'center'}>
                            <img src={Books} alt='resume' width='12%' style={{marginBottom: '10px'}} />
                            <Typography variant='body1' paragraph color='textSecondary'>You have no education list on your profile </Typography>
                            <Button variant='contained' disableElevation onClick={()=> onHandleOpen('education')} >Add Education</Button>
                        </Box>
                      }
                      
                    </CardContent>
                  </Card>

                  {/* RESUME */}
                  <Card variant='outlined' elevation={0} className={classes.card}>
                    <input type={'file'} accept='.pdf' ref={resume} style={{display: 'none'}} onChange={(e)=> uploadResume(e.target.files[0])} />
                    <CardContent>
                      <Box display='flex' justifyContent={'space-between'} marginBottom='1rem' alignItems={'center'}>
                        <Typography className={classes.title} variant='h6'>Resume</Typography>
                        <IconButton className={classes.iconBtn} onClick={() => resume.current.click()}><Edit fontSize='small' /></IconButton>
                      </Box>

                      {
                        props.currentUser && props.currentUser.resume ?
                        <Box>
                          <Divider style={{marginBottom: '2rem'}} />
                            <ResumeItem name={props.currentUser.resume.originalname} size={props.currentUser.resume.size} date={new Date(props.currentUser.resume.createdAt).toDateString()} view={()=>{}} /> 
                        </Box>
                        :
                        <Box textAlign={'center'}>
                            <img src={File} alt='resume' width='12%' style={{marginBottom: '10px'}} />
                            <Typography variant='body1' paragraph color='textSecondary'>You have no resume attached to your profile</Typography>
                            <Button variant='contained' onClick={() => resume.current.click()} disableElevation  >Add Resume</Button>
                      </Box>
                      }
                      
                      

                    </CardContent>
                  </Card>

                </Grid>
              </Grid>
           </Container>

              {/* ABOUT */}
              <Dialog open={ open === 'about' ? true : false } maxWidth='sm' fullWidth>
                  <DialogTitle >About Me</DialogTitle>
                  <DialogContent>
                    <TextField variant='outlined' placeholder='Tell us about yourself' value={formInput.about} multiline rows={8} onChange={(e)=>dispatch({type: "ABOUT", payload: e.target.value})} fullWidth/>
                  </DialogContent>
                  <DialogActions style={{padding: '1rem 2rem'}}>
                    <Button onClick={onHandleClose}>Cancel</Button>
                    <Button variant='contained' color='primary' disableElevation onClick={()=>onFormSubmit('about')}>Save</Button>
                  </DialogActions>
              </Dialog>

              {/* EXPERIENCE */}
              <Dialog open={ open === 'experience' ? true : false } maxWidth='sm' fullWidth>
                  <DialogTitle >Experience</DialogTitle>
                  <DialogContent>
                      <TextField variant='outlined' className={classes.field} label='Title' value={exInput.title} onChange={(e)=>exDispatch({type: "TITLE", payload: e.target.value})} fullWidth/>
                      <TextField variant='outlined' className={classes.field} label='Company' value={exInput.company} onChange={(e)=>exDispatch({type: "COMPANY", payload: e.target.value})} fullWidth/>
                  <Grid container spacing={3}>
                      <Grid item sm={6}>
                        <TextField variant='outlined' className={classes.field} type={'date'} helperText='From' value={exInput.from} onChange={(e)=>exDispatch({type: "FROM", payload: e.target.value})} fullWidth/>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField variant='outlined' className={classes.field} type={'date'} helperText='To' value={exInput.to} onChange={(e)=>exDispatch({type: "TO", payload: e.target.value})} fullWidth/>
                      </Grid>
                  </Grid>
                  </DialogContent>
                  <DialogActions style={{padding: '1rem 2rem'}}>
                    <Button onClick={onHandleClose}>Cancel</Button>
                    <Button variant='contained' color='primary' disableElevation onClick={() => onFormSubmit('experience')}>Save</Button>
                  </DialogActions>
              </Dialog>

              {/* EDUCATION */}
              <Dialog open={ open === 'education' ? true : false } maxWidth='sm' fullWidth>
                  <DialogTitle >Education</DialogTitle>
                  <DialogContent>
                      <TextField variant='outlined' className={classes.field} label='Institution' placeholder='University of Ghana' value={eduInput.institution} onChange={(e)=>eduDispatch({type: "INSTITUTION", payload: e.target.value})} fullWidth/>
                      <TextField variant='outlined' className={classes.field} label='Degree' placeholder='BA Graphic Design' value={eduInput.degree} onChange={(e)=>eduDispatch({type: "DEGREE", payload: e.target.value})} fullWidth/>
                      <TextField variant='outlined' className={classes.field} label='Major' placeholder='Graphic Design' value={eduInput.major} onChange={(e)=>eduDispatch({type: "MAJOR", payload: e.target.value})} fullWidth/>
                  <Grid container spacing={3}>
                      <Grid item sm={6}>
                        <TextField variant='outlined' className={classes.field} type={'date'} helperText='From' value={eduInput.from} onChange={(e)=>eduDispatch({type: "FROM", payload: e.target.value})} fullWidth/>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField variant='outlined' className={classes.field} type={'date'} helperText='To' value={eduInput.to} onChange={(e)=>eduDispatch({type: "TO", payload: e.target.value})} fullWidth/>
                      </Grid>
                  </Grid>
                  </DialogContent>
                  <DialogActions style={{padding: '1rem 2rem'}}>
                    <Button onClick={onHandleClose}>Cancel</Button>
                    <Button variant='contained' color='primary' disableElevation onClick={() => onFormSubmit('education')}>Save</Button>
                  </DialogActions>
              </Dialog>

              {/* LOADER */}
              <Dialog open={loader} >
                  <Box padding={'2.5rem'} textAlign={'center'}>
                      {/* <img src={User} alt='update' width={'25%'}/> */}
                      <Typography paragraph>Updating your account. Please wait...</Typography>
                      <CircularProgress size={30} />
                  </Box>
              </Dialog>
        </>
      )
    }

    return <Loader />
  }

  return (
    <div className={classes.root}>
            
       {renderPage()}

    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps ,{errorModal, editUserProfile, uploadUserPhoto, uploadUserCoverImg, successModal, deleteUserProfile, uploadUserResume})(Profile)
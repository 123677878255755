import {
  Button,
  Container,
  Grid,
  Link,
  Typography,
  Box,
  Badge,
  Avatar,
  IconButton,
  MenuItem,
  Divider,
  Popover,
  FormControlLabel,
  Switch,
  Hidden,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  Notifications,
  Person,
  Menu,
  Home,
  Work,
  Info,
  MonetizationOn,
  Lock,
  ExitToApp,
  Help,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useLocation } from "react-router-dom";
import React, { useState } from "react";
import { connect } from "react-redux";
import { grey } from "@material-ui/core/colors";
import { logoutUser } from "../actions/actions";
import LogoWhite from "../assets/internse.png";
import LogoBlue from "../assets/internse-logo.png";
import NotificationItem from "./NotificationItem";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 99,
    "& .MuiBadge-colorPrimary": {
      color: "#fff",
    },
  },
  title: {
    fontWeight: 600,
  },
  navLinks: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
  },
  auth: {
    display: "flex",
    gap: "1rem",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
  },
  iconBtn: {
    background: grey[200],
    padding: ".6rem",
  },
  photo: {
    margin: "0 auto",
    marginBottom: ".5rem",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  menu: {
    color: grey[600],
  },
  mobileNav: {
    padding: ".4rem",
    background: "#fff",
    borderRadius: "5px",
  },
  logo: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  logoIcon: {
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}));

const Navbar = (props) => {
  const path = useLocation().pathname;
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [val, setValue] = React.useState("");

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onhandleClick = (e, val) => {
    if (val === "user") {
      setValue(val);
      setAnchorEl(e.currentTarget);
    }
    if (val === "notifications") {
      setValue(val);
      setAnchorEl(e.currentTarget);
    }
  };
  const toDashboard = (link) => {
    if (props.currentUser.isCompany) {
      window.location.assign(`/company/${link}`);
    } else {
      window.location.assign(`/account/${link}`);
    }
  };

  const renderNav = () => {
    if (props.currentUser) {
      if (props.currentUser.isEmailVerified) {
        return (
          <Box className={classes.auth} gridGap="0.6rem">
            <Badge
              variant="standard"
              badgeContent={
                props.notifications ? props.notifications.length : 0
              }
              color="primary"
              overlap="circular"
            >
              <IconButton
                className={classes.iconBtn}
                onClick={(e) => onhandleClick(e, "notifications")}
              >
                <Notifications />
              </IconButton>
            </Badge>

            <Avatar
              src={
                props.currentUser.photo && props.currentUser.photo.profile
                  ? `${props.currentUser.photo.profile}`
                  : null
              }
              style={{ cursor: "pointer" }}
              onClick={(e) => onhandleClick(e, "user")}
            />
            <Hidden smDown>
              <Button
                variant="text"
                endIcon={<KeyboardArrowDown />}
                onClick={(e) => onhandleClick(e, "user")}
                size="large"
                style={{
                  textTransform: "none",
                  color: `${path === "/" && "#fff"}`,
                }}
              >
                {props.currentUser.isCompany
                  ? props.currentUser.companyName
                  : props.currentUser.firstname}
              </Button>
            </Hidden>

            {/* POPOVER */}
            <Popover
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              {val === "user" && (
                <>
                  {props.currentUser.isCompany ? (
                    <>
                      <Box
                        padding="1rem"
                        textAlign="center"
                        bgcolor="#3156a2"
                        color="#fff"
                      >
                        <Avatar
                          className={classes.photo}
                          src={
                            props.currentUser.photo &&
                            props.currentUser.photo.profile
                              ? `${props.currentUser.photo.profile}`
                              : null
                          }
                        />
                        <Typography variant="h6" style={{ fontSize: "1rem" }}>
                          {props.currentUser.companyName}
                        </Typography>
                        <Typography gutterBottom variant="body2" noWrap>
                          {props.currentUser.email}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box padding={".5rem"}>
                        <MenuItem onClick={() => toDashboard("new-listing")}>
                          Post A Job
                        </MenuItem>
                        <MenuItem onClick={() => toDashboard("listing")}>
                          Job Listings
                        </MenuItem>
                        <MenuItem onClick={() => toDashboard("applications")}>
                          Applications
                        </MenuItem>
                        <MenuItem onClick={() => toDashboard("settings")}>
                          Account Settings
                        </MenuItem>
                        <MenuItem onClick={() => {}}>Help & Support</MenuItem>
                      </Box>
                      <Divider />
                      <Box padding={".5rem"}>
                        <MenuItem onClick={() => {}}>
                          {props.currentUser.plan
                            ? `${props.currentUser.plan.name} Plan (${props.currentUser.plan.credit} Listings)`
                            : "No Selected Plan"}
                        </MenuItem>
                      </Box>
                      <Divider />
                      <Box padding={".5rem"}>
                        <MenuItem onClick={() => props.logoutUser()}>
                          Logout
                        </MenuItem>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        padding="1rem"
                        width={"12rem"}
                        textAlign="center"
                        bgcolor="#3156a2"
                        color="#fff"
                      >
                        <Avatar
                          className={classes.photo}
                          src={
                            props.currentUser.photo &&
                            props.currentUser.photo.profile
                              ? `${props.currentUser.photo.profile}`
                              : null
                          }
                        />
                        <Typography variant="h6" style={{ fontSize: "1rem" }}>
                          {props.currentUser.firstname}
                        </Typography>
                        <Typography gutterBottom variant="body2" noWrap>
                          {props.currentUser.email}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box padding={".5rem"}>
                        <MenuItem onClick={() => toDashboard("profile")}>
                          Career Profile
                        </MenuItem>
                        <MenuItem onClick={() => toDashboard("applications")}>
                          My Applications
                        </MenuItem>
                        <MenuItem onClick={() => toDashboard("saved-jobs")}>
                          Saved Jobs
                        </MenuItem>
                        <MenuItem onClick={() => toDashboard("settings")}>
                          Account Settings
                        </MenuItem>
                        <MenuItem onClick={() => {}}>Help & Support</MenuItem>
                      </Box>
                      <Divider />
                      <Box padding={".5rem 1.2rem"}>
                        <FormControlLabel
                          control={<Switch checked={true} />}
                          label="Open To Work"
                        />
                      </Box>
                      <Divider />
                      <Box padding={".5rem"}>
                        <MenuItem onClick={() => props.logoutUser()}>
                          Logout
                        </MenuItem>
                      </Box>
                    </>
                  )}
                </>
              )}
              {val === "notifications" && (
                <>
                  <Box padding={"1rem"} width="20rem">
                    <Typography variant="h6" gutterBottom>
                      Notifications(
                      {props.notifications ? props.notifications.length : 0})
                    </Typography>
                    <Divider style={{ margin: "1rem 0" }} />
                    <Box>
                      {props.notifications && props.notifications.length > 0 ? (
                        props.notifications.map((el) => {
                          return (
                            <NotificationItem
                              key={el.id}
                              title={el.title}
                              subject={el.subject}
                              body={el.body}
                            />
                          );
                        })
                      ) : (
                        <Typography>Your have no notifications</Typography>
                      )}
                    </Box>
                    <Divider style={{ margin: "1rem 0" }} />
                    <Button
                      style={{ textAlign: "right" }}
                      href={
                        props.currentUser && props.currentUser.isCompany
                          ? "/company/notifications"
                          : "/account/notifications"
                      }
                    >
                      View all
                    </Button>
                  </Box>
                </>
              )}
            </Popover>
          </Box>
        );
      } else {
        return (
          <Box className={classes.auth}>
            <Button
              href="/verify"
              size="large"
              startIcon={<Person />}
              style={{ color: `${path === "/" && "#fff"}` }}
            >
              Login
            </Button>
            <Button
              href="/verify"
              variant="contained"
              disableElevation
              size="large"
              color="primary"
              style={{ color: "#fff" }}
            >
              Register
            </Button>
          </Box>
        );
      }
    }
    return (
      <Box className={classes.auth}>
        <Button
          href="/login"
          size="large"
          startIcon={<Person />}
          style={{ color: `${path === "/" && "#fff"}` }}
        >
          Login
        </Button>
        <Hidden xsDown>
          <Button
            href="/register"
            variant="contained"
            disableElevation
            size="large"
            color="primary"
            style={{ color: "#fff" }}
          >
            Register
          </Button>
        </Hidden>
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <Box
        style={{ transition: "all .3s ease" }}
        boxShadow={path === "/" ? null : "0 2px .6rem rgba(0,0,0, 0.1)"}
      >
        <Container>
          <Grid container spacing={3} style={{ height: "6rem", margin: 0 }}>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Box className={classes.logo}>
                <Hidden mdUp>
                  <IconButton
                    color="primary"
                    className={classes.mobileNav}
                    onClick={() => setDrawer(true)}
                  >
                    <Menu fontSize="medium" />
                  </IconButton>
                </Hidden>
                <img
                  src={path === "/" ? LogoWhite : LogoBlue}
                  className={classes.logoIcon}
                  alt="logo"
                  onClick={() => window.location.assign("/")}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Grid>

            <Hidden smDown>
              <Grid item sm={8} md={6} style={{ zIndex: "99" }}>
                <Box className={classes.navLinks}>
                  <Link
                    underline="none"
                    href="/"
                    style={{ color: `${path === "/" ? "#fff" : "#757575"}` }}
                  >
                    <Typography>Home</Typography>
                  </Link>
                  <Link
                    underline="none"
                    href="/joblists"
                    style={{ color: `${path === "/" ? "#fff" : "#757575"}` }}
                  >
                    <Typography>Find A Job</Typography>
                  </Link>
                  <Link
                    underline="none"
                    href="/pricing"
                    style={{ color: `${path === "/" ? "#fff" : "#757575"}` }}
                  >
                    <Typography>Pricing</Typography>
                  </Link>
                  {/* <Link underline='none' href='/about' style={{color: `${path === '/' ? '#fff' : '#757575'}` }} ><Typography>About</Typography></Link> */}
                </Box>
              </Grid>
            </Hidden>

            <Grid item xs={6} sm={4} md={3} style={{ marginLeft: "auto" }}>
              {renderNav()}
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Drawer open={drawer} onClose={() => setDrawer(false)}>
        <Box
          width={"240px"}
          display="flex"
          flexDirection={"column"}
          height="98%"
        >
          <Box bgcolor={"#3156a2"} padding="2rem">
            <img src={LogoWhite} width="90%" alt="logo" />
          </Box>

          <List>
            <ListItem button onClick={() => window.location.assign("/")}>
              <ListItemIcon>
                <Home color="primary" />
              </ListItemIcon>
              <ListItemText>Home</ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={() => window.location.assign("/joblists")}
            >
              <ListItemIcon>
                <Work color="primary" />
              </ListItemIcon>
              <ListItemText>Find A Job</ListItemText>
            </ListItem>
            <ListItem button onClick={() => window.location.assign("/pricing")}>
              <ListItemIcon>
                <MonetizationOn color="primary" />
              </ListItemIcon>
              <ListItemText>Pricing</ListItemText>
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <Info color="primary" />
              </ListItemIcon>
              <ListItemText>About</ListItemText>
            </ListItem>
          </List>
          <Box marginTop={"auto"}>
            <Divider />
            {!props.currentUser ? (
              <List>
                <ListItem
                  button
                  onClick={() => window.location.assign("/login")}
                >
                  <ListItemIcon>
                    <Lock color="primary" />
                  </ListItemIcon>
                  <ListItemText>Login</ListItemText>
                </ListItem>
                <ListItem
                  button
                  onClick={() => window.location.assign("/register")}
                >
                  <ListItemIcon>
                    <ExitToApp color="primary" />
                  </ListItemIcon>
                  <ListItemText>Register</ListItemText>
                </ListItem>
              </List>
            ) : (
              <List>
                <ListItem button>
                  <ListItemIcon>
                    <Help color="primary" />
                  </ListItemIcon>
                  <ListItemText>Help & Support</ListItemText>
                </ListItem>
                <ListItem button onClick={() => props.logoutUser()}>
                  <ListItemIcon>
                    <ExitToApp color="primary" />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </ListItem>
              </List>
            )}
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { logoutUser })(Navbar);

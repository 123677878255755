import { Box, Button, Card, CardContent, Container, Grid, Hidden, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { KeyboardArrowDown, Search, ViewList, ViewModule } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import CheckBoxFilter from '../components/CheckBoxFilter'
import JobAlert from '../components/JobAlert'
import JobItem from '../components/JobItem'
import Loader from '../components/Loader'


const useStyles = makeStyles(theme => ({
  root : {
    '& *': {
      borderRadius: '10px',
    }
  },
  card : {
    marginTop: '1.5rem'
  },
  filter: {
    '& span' :{
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  iconBtn: {
    height: '100%',
    padding: '.5rem',
    background: grey[200]
  },
  search : {
    marginTop: '1rem'
  }
}))
const Internships = (props) => {
    const classes = useStyles()
    const [view, setView] = useState('list')

    const filter1 = [
      {title: 'Full Time', number: '34'},
      {title: 'Part Time', number: '22'},
      {title: 'Remote Work', number: '06'},
      {title: 'Internship', number: '62'}
    ]

    const experience = [
      {title: 'Entry Level', number: '34'},
      {title: 'Intermediate Level', number: '22'},
      {title: 'Senior Level', number: '06'},
    ]

  return (
    <div className={classes.root}>
        <Box padding={'6rem 0'} bgcolor={'#f5f5f538'}>
          <Container>
            <Grid container spacing={3}>

              <Grid item lg={3}>
                <JobAlert />

                {/* Category */}
                <Card variant='outlined' elevation={0} className={classes.card}>
                  <CardContent style={{padding: '1.5rem'}}>
                      <Typography variant='h6' gutterBottom>Category</Typography>
                      <Box className={classes.filterList}>
                          <TextField size='medium' variant='outlined' fullWidth placeholder='Design, Accounting, etc' />
                          <Button variant='contained' disableElevation color='primary' style={{color:'#fff', marginTop: '.5rem', height: '2.5rem'}}>Find category</Button>
                      </Box>
                  </CardContent>
                </Card>

                {/* Internship Type */}
                <Card variant='outlined' elevation={0} className={classes.card}>
                  <CardContent style={{padding: '1.5rem'}}>
                      <Typography variant='h6' gutterBottom>Internship Type</Typography>
                      <Box className={classes.filterList}>
                          {
                            filter1.map(el => {
                              return <CheckBoxFilter key={el.title} title={el.title} number={el.number} />
                            })
                          }
                      </Box>
                  </CardContent>
                </Card>

                {/* Experience Level */}
                <Card variant='outlined' elevation={0} className={classes.card}>
                  <CardContent style={{padding: '1.5rem'}}>
                      <Typography variant='h6' gutterBottom>Experience Level</Typography>
                      <Box className={classes.filterList}>
                          {
                            experience.map(el => {
                              return <CheckBoxFilter key={el.title} title={el.title} number={el.number} />
                            })
                          }
                      </Box>
                  </CardContent>
                </Card>

                {/* Location */}
                <Card variant='outlined' elevation={0} className={classes.card} style={{marginBottom: '1.5rem'}}>
                  <CardContent style={{padding: '1.5rem'}}>
                      <Typography variant='h6' gutterBottom>Location</Typography>
                      <Box className={classes.filterList}>
                        <CheckBoxFilter title={'Near Me'} number={'05'} />
                         <CheckBoxFilter title={'Remote'} number={'30'} />
                          <TextField size='medium' variant='outlined' fullWidth placeholder='Accra, Tema, etc' />
                          <Button variant='contained' disableElevation color='primary' style={{color:'#fff', marginTop: '.5rem', height: '2.5rem'}}>Find Internship</Button>
                      </Box>
                  </CardContent>
                </Card>

                {/* JOB ALERt */}
                <Hidden smDown>
                  <JobAlert fix={true} />
                </Hidden>
              </Grid>



              <Grid item xs={12} sm={12} lg={9}>
                  {/* FILTERS */}
                <Box display={'flex'}>
                  <Typography variant='h6'>Showing {props.allJobs ? props.allJobs.filter(el => el.status === 'Published').length : 0} Jobs</Typography>
                  <span style={{marginLeft: 'auto', display: 'flex', }}>
                    <Button size='large' endIcon={<KeyboardArrowDown />} >Sort by</Button>
                    <Hidden smDown>
                      <IconButton style={{padding: '.5rem'}} onClick={()=> setView('list')}><ViewList fontSize='large' /></IconButton>
                      <IconButton style={{padding: '.5rem'}} onClick={()=> setView('grid')}><ViewModule fontSize='large' /></IconButton> 
                    </Hidden>
                  </span>
                </Box>

                {/* Searchbar */}
                <TextField className={classes.search} InputProps={{
                  startAdornment: <InputAdornment position='start'><Search style={{color: grey[400]}} /></InputAdornment>
                }} size='medium' variant='outlined' fullWidth placeholder='Search for interships' />

                {/* JOB LISTS */}
                {
                  props.allJobs && props.allJobs.length > 0 ?
                  <Grid container spacing={3} style={{padding: '1.5rem 0'}}>
                    {
                      props.allJobs.filter(el => el.status === 'Published').map(el => {
                        return (
                          <Grid key={el.id} item xs={12} sm={12} lg={view === 'list' ? 12 : 6}>
                            <JobItem 
                            id={el.id}
                            mt={false} 
                            title={el.title} 
                            company={el.company.companyName} 
                            description={el.description}
                            type={el.jobType} 
                            level={view === 'list' ? `${el.experience} Years` : null} 
                            category={el.industry}
                            slug={el.slug}
                            />
                          </Grid>
                        )
                      })
                    }
                  </Grid> :
                  <Loader />
                }


              </Grid>

            </Grid>
          </Container>
        </Box>
    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {})(Internships)
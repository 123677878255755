import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiListItemAvatar-root': {
      minWidth: '52px'
    }
  },
  title: {
    fontSize: "1rem",
  },
  avatar: {
    background: theme.primaryColor,
   
  },
}));

const NotificationItem = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MenuItem style={{ padding: "0 1rem", borderRadius: "10px" }}>
        <List style={{ padding: "0", display: "contents" }}>
          <ListItem style={{ padding: ".5rem 0" }}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>{props.subject.charAt(0)}</Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h6" className={classes.title} noWrap>
                {props.subject}
              </Typography>
              <Typography variant="body2" color="textSecondary" noWrap>
                {props.body}
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </MenuItem>
    </div>
  );
};

export default NotificationItem;

import { Avatar, Box, Button, Card, CardContent, Chip, Container, Dialog, DialogContent, Divider, Grid, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { CheckCircle, Close, Search } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Listing from '../../assets/listing.png'
import Loader from '../../components/Loader'



const useStyles = makeStyles(theme => ({
    root: {
        
    },
    search: {
        marginBottom: '2rem'
    },
    avatar : {
        width: theme.spacing(8),
        height: theme.spacing(8)
    },
    avatar2 : {
        width: theme.spacing(14),
        height: theme.spacing(14),
        marginTop: '-4rem',
    },
    wrap : {
        padding: '1.5rem',
    },
    card: {
        borderRadius: '10px',
        transition: 'all .2s ease',
        '&:hover' : {
            border: `1px solid ${theme.primaryColor}`
        }
    },
    divider:{
        margin: '1rem 0'
    }
}))

const SingleApplication = (props) => {
    const classes = useStyles()
    const { jobs, job } = props
    const [ applicants, setApplicants ] = useState(null)
    const [ view, setView] = useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if( jobs && !job ){
            window.location.assign('/company/applications')
        }
        if(jobs && job){
            const fetchData = async() => {
                const res = await axios.get(`/api/all/jobs/${job._id}/applications`)
                setApplicants(res.data.data)
            }
            fetchData()
        }
    },[jobs, job])


    const viewApplicant = (obj) => {
        setOpen(true)
        setView(obj)
    }

    const renderPage = () => {
        if(jobs && job && applicants){
            //console.log(job)
            return (
                <Container>

                        <Grid container spacing={3}>
                            <Grid item lg={8}>
                                {/* Searchbar */}
                                <TextField className={classes.search} InputProps={{
                                    startAdornment: <InputAdornment position='start'><Search style={{color: grey[400]}} /></InputAdornment>
                                }} size='medium' variant='outlined' fullWidth placeholder='Search for interships' />
                                
                                <Grid container spacing={3}>

                                    {
                                        applicants && applicants.length > 0 ? applicants.map(el => {
                                            return (
                                               
                                                    <Grid item lg={6} key={el.id}>
                                                        <Card variant='outlined' className={classes.card} >
                                                            <CardContent className={classes.wrap} >
                                                                <Box display={'flex'} gridGap='1rem'>
                                                                    <Avatar className={classes.avatar} src={el.user.photo && el.user.photo.profile ? el.user.photo.profile : null} />
                                                                    <Box>
                                                                        <Typography variant='h6'>{el.user.fullname}</Typography>
                                                                        <Typography variant='body2' gutterBottom color='textSecondary'>{el.user.email}</Typography>
                                                                        <Typography variant='body2' gutterBottom color='textSecondary'>(+233) {el.user.phone}</Typography>
                                                                        
                                                                    </Box>

                                                                </Box>
                                                                <Divider style={{margin: '10px 0'}} />
                                                                <Box display={'flex'} justifyContent='space-between'>
                                                                    <span>
                                                                        <Typography variant='body2'>Applied</Typography>
                                                                        <Typography variant='body2' gutterBottom color='textSecondary'> {new Date(el.createdAt).toDateString()}</Typography>
                                                                    </span>
                                                                    <Button variant='contained' onClick={()=>viewApplicant(el.user)} disableElevation size='small' color='primary' style={{textTransform: 'none', borderRadius: '6px', marginTop: '.5rem'}}>View Applicant</Button>
                                                                </Box>

                                                            
                                                            </CardContent>
                                                        </Card>
                                                    </Grid >
                                            )
                                        }): 
                                        <Grid item lg={12}>
                                        <Box padding={'6rem'} bgcolor={'#fff'} border='1px dashed lightgrey' borderRadius={'10px'} marginBottom={'3rem'}>
                                                <Box textAlign={'center'} marginBottom='2rem'>
                                                    <img src={Listing} alt='job-listing' width='20%' style={{margin:'0 auto', marginBottom: '2rem'}} />
                                                    <Typography variant='h5' gutterBottom>{`Manage Your Job Applications`}</Typography>
                                                    <Typography color='textSecondary'>{`You have no applications for this job post`}</Typography>
                                                </Box>
                                                
                                        </Box>
                                        </Grid>
                                    }

                                </Grid>
                            </Grid>

                            <Grid item lg={4}>
                                <Card variant='outlined'>
                                    <Box textAlign={'center'} padding='1.5rem' bgcolor={'#3156a2'} color='#fff'>
                                        <Typography variant='h6'>{job.title}</Typography>
                                        <Typography color='textSecondary' style={{color:"#fff"}}>{job.company.companyName} - {job.jobType}</Typography>
                                    </Box>
                                    <CardContent style={{padding: '2rem'}}>
                                        <Typography variant='h6' color='textPrimary' gutterBottom>Job description</Typography>
                                        <Typography color='textSecondary'>{job.description}</Typography>
                                        <Divider style={{margin: '1rem 0'}} />
                                        <Typography color='textSecondary' paragraph>Experience - {job.experience} Years</Typography>
                                        <Typography color='textSecondary' paragraph>Qualifications - {job.qualification}</Typography>
                                        <Typography color='textSecondary' paragraph>Industry - {job.industry}</Typography>
                                        <Divider style={{margin: '1rem 0'}} />

                                        <Typography variant='h6' color='textPrimary'>Requirements</Typography>
                                        <List disablePadding>
                                        {
                                            job && job.requirements.map((el, index) => {
                                                return (
                                                    <ListItem key={index} disableGutters>
                                                        <ListItemIcon><CheckCircle fontSize='small' style={{minWidth: '30px'}}  /></ListItemIcon>
                                                        <ListItemText>
                                                            <Typography color='textSecondary'>{el}</Typography>
                                                        </ListItemText>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                </Container>
            )
        }

        return <Loader />

    }

  return (
    <div className={classes.root}>
        
            {renderPage()}

            {/* DAILOG */}
            <Dialog open={open} maxWidth='md' fullWidth>
                <DialogContent style={{padding: 0, position:'relative'}}>
                    <Box height={'10rem'} bgcolor='lightgrey' style={{backgroundImage: view.photo && view.photo.coverImage ? `url(${view.photo.coverImage})` : null, backgroundPosition: 'center', backgroundSize: 'cover'}}></Box>
                    <IconButton style={{position:'absolute', top:'2%', right: '2%', background: '#fff'}} onClick={()=>setOpen(false)}><Close /></IconButton>
                    <Box padding={'2.5rem'}>
                        <Box display='flex' gridGap='2rem'>
                            <Avatar className={classes.avatar2} src={view.photo && view.photo.profile ? view.photo.profile : null} />
                            <span>
                                <Typography variant='h5'>{view.fullname}</Typography>
                                <Typography variant='body1' gutterBottom color='textSecondary'>{view.email}</Typography>
                                <Typography variant='body1' gutterBottom color='textSecondary'>(+233) {view.phone}</Typography>
                            </span>
                            <Button variant='contained' onClick={()=>{}} disableElevation size='small' color='primary' style={{textTransform: 'none', borderRadius: '6px', marginLeft: 'auto', height: '2.3rem'}}>Download Resume</Button>
                        </Box>
                        <Divider className={classes.divider} />
                        <Grid container spacing={4} >
                            <Grid item lg={8} style={{borderRight: '1px solid lightgrey'}}>
                                <Box>
                                    <Typography variant='h6' gutterBottom>About</Typography>
                                    <Typography variant='body1' color='textSecondary'>{view.about}</Typography>
                                </Box>
                                <Divider className={classes.divider} />
                                <Box>
                                    <Typography variant='h6' gutterBottom>Experience</Typography>

                                    {
                                        view.experience && view.experience.map((el, index) => {
                                            return (
                                                <div key={index}>
                                                    <Box display='flex' gridGap={'1rem'}>
                                                        <Avatar>{el.company.charAt(0)}</Avatar>
                                                        <span>
                                                            <Typography variant='body1' color='textPrimary'>{el.company}</Typography>
                                                            <Typography variant='body1' gutterBottom color='textSecondary'>{el.title}</Typography>
                                                            <Typography variant='body2' color='textSecondary'>{new Date(el.from).getFullYear()} - {new Date(el.to).getFullYear()}</Typography>
                                                        </span>
                                                    </Box>
                                                   
                                                      <Divider className={classes.divider} />
                                                </div>
                                            )
                                        })
                                    }

                                </Box>

                                <Box>
                                    <Typography variant='h6' gutterBottom>Education</Typography>
                                    {
                                        view.education && view.education.map((el, index) => {
                                            return (
                                                <div key={index}>
                                                    <Box display='flex' gridGap={'1rem'}>
                                                        <Avatar>{el.institution.charAt(0)}</Avatar>
                                                        <span>
                                                            <Typography variant='body1' color='textPrimary'>{el.institution}</Typography>
                                                            <Typography variant='body1' color='textSecondary'>{el.degree}</Typography>
                                                            <Typography variant='body2' color='textSecondary'>{el.major}</Typography>
                                                            <Typography variant='body2' color='textSecondary'>{new Date(el.from).getFullYear()} - {new Date(el.to).getFullYear()}</Typography>
                                                        </span>
                                                    </Box>
                                                   
                                                      <Divider className={classes.divider} />
                                                </div>
                                            )
                                        })
                                    }
                                </Box>
                            </Grid>
                            
                            <Grid item lg={4}>
                                <Box>
                                    <Typography variant='h6' gutterBottom>Qualification</Typography>
                                    <Chip label={'Degree'} />
                                    <Divider className={classes.divider} />
                                    <Typography variant='h6' gutterBottom>Skills</Typography>
                                        {
                                            view.skills && view.skills.map((el, index) => {
                                                return (
                                                    <Chip key={index} label={el} style={{marginRight: '8px', marginBottom: '8px'}} />
                                                )
                                            })
                                        }
                                    <Divider className={classes.divider} />
                                    <Typography variant='h6' gutterBottom>Languages</Typography>
                                    {
                                            view.languages && view.languages.map((el, index) => {
                                                return (
                                                    <Chip key={index} label={el} style={{marginRight: '8px', marginBottom: '8px'}} />
                                                )
                                            })
                                        }
                                </Box>
                            </Grid>
                        </Grid>
                        
                        
                    </Box>
                </DialogContent>
            </Dialog>
        
    </div>
  )
}


const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {})(SingleApplication)
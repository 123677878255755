import "./App.css";
import { ThemeProvider } from "@material-ui/styles";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Home from "./pages/Home.js";
import { theme } from "./theme.js";
import Login from "./pages/Login";
import Register from "./pages/Register";
import JobLists from "./pages/JobLists";
import Account from "./pages/user/Account";
import {
  authRequest,
  userNotifications,
  companyNotifications,
  getAllJobs,
  getCompanyJobs,
} from "./actions/actions";
import { useEffect } from "react";
import { connect } from "react-redux";
import Verify from "./pages/Verify";
import Loader from "./components/Loader";
import Company from "./pages/employer/Company";
import SingleJobList from "./pages/SingleJobList";
import Pricing from "./pages/Pricing";
import Footer from "./components/Footer";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";

function App(props) {
  const {
    authRequest,
    currentUser,
    loader,
    getAllJobs,
    userNotifications,
    companyNotifications,
    getCompanyJobs,
  } = props;
  const path = useLocation().pathname;

  useEffect(() => {
    authRequest();
    getAllJobs();
  }, [authRequest, getAllJobs]);

  useEffect(() => {
    if (currentUser && currentUser.isCompany) {
      companyNotifications();
      getCompanyJobs();
    }
    if (currentUser && !currentUser.isCompany) {
      userNotifications();
    }
  }, [userNotifications, companyNotifications, currentUser, getCompanyJobs]);

  const renderRegister = () => {
    if (currentUser) {
      if (currentUser.isEmailVerified === false) {
        return <Navigate to="/verify" />;
      } else {
        return <Navigate to="/" />;
      }
    }
    return <Register />;
  };

  return (
    <ThemeProvider theme={theme}>
      {!loader ? (
        <>
          {path.startsWith("/verify") ? null : <Navbar />}
          <Routes>
            {/* <Route exact path='/' element={<WaitList />} /> */}
            <Route exact path="/" element={<Home />} />
            <Route
              exact
              path="/login"
              element={currentUser ? <Navigate to="/" /> : <Login />}
            />
            <Route exact path="/register" element={renderRegister()} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />

            <Route exact path="/verify" element={<Verify />} />
            <Route exact path="/joblists" element={<JobLists />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/joblists/:title" element={<SingleJobList />} />

            {/* //User Account */}
            <Route exact path="/account/profile" element={<Account />} />
            <Route exact path="/account/settings" element={<Account />} />
            <Route exact path="/account/applications" element={<Account />} />
            <Route exact path="/account/saved-jobs" element={<Account />} />

            {/* Company Account */}
            <Route exact path="/company/listing" element={<Company />} />
            <Route exact path="/company/new-listing" element={<Company />} />
            <Route exact path="/company/applications" element={<Company />} />
            <Route
              exact
              path="/company/applications/:slug"
              element={<Company />}
            />
            <Route exact path="/company/settings" element={<Company />} />
          </Routes>

          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  console.log(state);
  return state;
};

export default connect(mapStateToProps, {
  authRequest,
  userNotifications,
  companyNotifications,
  getAllJobs,
  getCompanyJobs,
})(App);

import { Avatar, Box, Button, Card, CardContent, Chip, CircularProgress, Container, Dialog, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useReducer, useState } from 'react'
import { connect } from 'react-redux'
import Cover from '../../assets/cover.jpg'
import { errorModal, successModal, editUserSettings } from '../../actions/actions'
import Modal from '../../components/Modal'

const useStyles = makeStyles(theme => ({
  root:  {
    paddingTop: '3rem'

  },
  cover : {
    height: '10rem',
    borderRadius: '15px',
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: '0 auto',
    marginBottom: '.5rem',
    border: '6px solid #fff'
  },
  title : {
    marginTop: '2rem',
    marginBottom: '-1rem',
    fontWeight: 500
  },
  card : {
    marginTop: '2rem',
    padding: '1rem',
    borderRadius: '15px',
    transition: 'all .2s ease',
    '&:hover': {
        boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)',
        border: `1px solid ${theme.secondaryColor}`
      }
  },
  field : {
    marginBottom: '1.5rem'
  }
}))


const Settings = (props) => {
  const classes = useStyles()
  const [loader, setLoader] = useState(false)

  const infoState = {
    firstname: '',
    lastname: '',
    phone: '',
  }

  const passState = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  }

  const infoReducer = (state, action) => {
    switch (action.type) {
      case "FNAME":
        return {...state, firstname: action.payload.toUpperCase()}
      case "LNAME":
        return {...state, lastname: action.payload.toUpperCase()}
      case "PHONE":
        return {...state, phone: action.payload}
      case "RESET":
        return infoState
      default:
        return state;
    }
  }

  const passReducer = (state, action) => {
    switch (action.type) {
      case "CURRENT":
        return {...state, currentPassword: action.payload}
      case "NEW":
        return {...state, newPassword: action.payload}
      case "CONFIRM":
        return {...state, confirmPassword: action.payload}
      case "RESET":
        return passState
      default:
        return state;
    }
  }

  const [infoInput, infoDispatch] = useReducer(infoReducer, infoState)
  const [passInput, passDispatch] = useReducer(passReducer, passState)


  const onFormSubmit = (val) => {
    if(val === 'personal'){
      if(infoInput.firstname === '' && infoInput.lastname === '' && infoInput.phone === ''){
        return
      }
      const data = { 
        field: val,
        firstname: infoInput.firstname.trim(),
        lastname: infoInput.lastname.trim(),
        phone: infoInput.phone.trim()
       }
       setLoader(true)

       //call action creator
       setTimeout(async() => {
        const res = await props.editUserSettings(data)
        if(res.status === 'success'){
          infoDispatch({type: "RESET"})
          setLoader(false)
          props.successModal('User settings updated successfully')
        }else{
          setLoader(false)
          props.errorModal('Sorry, could not update setting. Try again')
        }
       }, 1000);
    }
    if(val === 'password'){
      if(passInput.currentPassword === '' || passInput.newPassword === '' || passInput.confirmPassword === ''){
        return
      }
      if(passInput.newPassword !== passInput.confirmPassword ){
        props.errorModal('Sorry, passwords do not match')
        return
      }
      const data = {
        field: val,
        currentPassword: passInput.currentPassword,
        newPassword: passInput.newPassword
      }

      setLoader(true)
      console.log(data)
      //call action creator
      setTimeout(async() => {
        const res = await props.editUserSettings(data)
        if(res.status === 'success'){
          passDispatch({type: "RESET"})
          setLoader(false)
          props.successModal('User settings updated successfully')
        }else{
          setLoader(false)
          props.errorModal('Sorry, could not update setting. Try again')
        }
       }, 1000);
    }
  }

  return (
    <div className={classes.root}>
      {props.modal && <Modal status={props.modal.status} />}
      <Container>
        <Grid container spacing={4}>
          <Grid item sm={4}>
              <Card variant='outlined' elevation={0} className={classes.card}>
                <Box className={classes.cover} style={{backgroundImage: props.currentUser.photo && props.currentUser.photo.coverImage ? `url(${props.currentUser.photo.coverImage})` : `url(${Cover})`}}></Box>
                <CardContent >
                    <Box textAlign={'center'} marginTop='-3.5rem'>
                      <Avatar className={classes.avatar} src={props.currentUser.photo && props.currentUser.photo.profile ? `${props.currentUser.photo.profile}` : null} />
                      <span>
                        <Typography variant='h6'>{props.currentUser.fullname}</Typography>
                        <Typography color='textSecondary'>{props.currentUser.email}</Typography>
                        <Typography gutterBottom color='textSecondary'>(+233) {props.currentUser.phone}</Typography>
                        <Chip color='primary' label={<Typography color='textSecondary' style={{color: '#fff'}} variant='body2'>Open To Work</Typography>} />
                      </span>
                  </Box>
                </CardContent>
              </Card>
          </Grid>

          <Grid item sm={8}>
            <Box>
              <Typography variant='h5' className={classes.title}>Personal Info.</Typography>
              <Card variant='outlined' elevation={0} className={classes.card}>
                  <CardContent>
                    <TextField className={classes.field} fullWidth variant='outlined' placeholder={props.currentUser && props.currentUser.email} disabled />
                    <TextField className={classes.field} fullWidth variant='outlined' label='Firstname' placeholder={props.currentUser && props.currentUser.firstname} value={infoInput.firstname} onChange={(e) => infoDispatch({type: 'FNAME', payload: e.target.value})} />
                    <TextField className={classes.field} fullWidth variant='outlined' label='Lastname' placeholder={props.currentUser && props.currentUser.lastname} value={infoInput.lastname} onChange={(e) => infoDispatch({type: 'LNAME', payload: e.target.value})} />
                    <TextField className={classes.field} type={'number'} inputProps={{min: 0}} fullWidth variant='outlined' label='Phone' placeholder={props.currentUser && props.currentUser.phone} value={infoInput.phone} onChange={(e) => infoDispatch({type: 'PHONE', payload: e.target.value})}/>
                    <Button variant='contained' disableElevation color='primary' onClick={() => onFormSubmit('personal')}>Update Details</Button>
                  </CardContent>
              </Card>
            </Box>

            <Box>
              <Typography variant='h5' className={classes.title}>Password & Security</Typography>
              <Card variant='outlined' elevation={0} className={classes.card}>
                  <CardContent>
                    <TextField className={classes.field} type={'password'} fullWidth variant='outlined' label='Current Password' value={passInput.currentPassword} onChange={(e) => passDispatch({type: 'CURRENT', payload: e.target.value})}  />
                    <TextField className={classes.field} type={'password'} fullWidth variant='outlined' label='New Password' value={passInput.newPassword} onChange={(e) => passDispatch({type: 'NEW', payload: e.target.value})}  />
                    <TextField className={classes.field} type={'password'} fullWidth variant='outlined' label='Confirm Password' value={passInput.confirmPassword} onChange={(e) => passDispatch({type: 'CONFIRM', payload: e.target.value})}  />
                    
                    <Button variant='contained' disableElevation color='primary' onClick={() => onFormSubmit('password')}>Update Password</Button>
                  </CardContent>
              </Card>
            </Box>


          </Grid>
        </Grid>
      </Container>

      {/* LOADER */}
      <Dialog open={loader} >
          <Box padding={'2.5rem'} textAlign={'center'}>
              {/* <img src={User} alt='update' width={'25%'}/> */}
              <Typography paragraph>Updating your settings. Please wait...</Typography>
              <CircularProgress size={30} />
          </Box>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps, {errorModal, successModal, editUserSettings})(Settings)
import { Avatar, Box, Button, Card, CardContent, Chip, CircularProgress, Dialog, Divider, IconButton, MenuItem, Popover, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Cancel, Delete, Edit, Favorite, FavoriteBorder, FileCopy, MoreVert, PeopleAlt } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { unmarkFavourite, markFavourite, successModal, errorModal, editJobStatus, deleteJob } from '../actions/actions'
import Modal from './Modal'

const useStyles = makeStyles(theme => ({
    root: {

    },
    card: {
        borderRadius: '15px',
        transition: 'all .3s ease',
        '&:hover': {
          boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)',
          border: `1px solid ${theme.primaryColor}`
        }
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        background: theme.secondaryColor
    },
    chips: {
        background: grey[200],
        marginRight: '.8rem'
    },
    icon :{
        color: grey[400],
        marginRight: '.8rem'
    },
    truncate: {
        textOverflow: 'ellipsis',
        overflow:'hidden',
        // Addition lines for 2 line or multiline ellipsis
        display: '-webkit-box !important',
        "-webkit-line-clamp": '3',
        "-webkit-box-orient": 'vertical',
        whiteSpace: 'normal',
    }
}))
const JobItem = (props) => {
    const {id, title, company, description, type, level, category, mt, slug, currentUser, status, applications} = props
    const path = useLocation().pathname
    const classes = useStyles()
    const [fav, setFav] = useState(false)
    const [save, setSave] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)

    console.log(path)

    useEffect(()=>{
        const isFavourite = () => {
            if(currentUser && !currentUser.isCompany && currentUser.favourites.length > 0){
                const res = currentUser.favourites.filter(el => el.job.id === id)[0]
                if(res){
                    setFav(true)
                } else{
                    setFav(false)
                }
            }
        }
        isFavourite()
    },[currentUser, id])



    const handleClose = () => {
        setAnchorEl(null);
    }

    const onhandleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const saveJob = () => {
        if(fav){
            setSave(true)
            setTimeout(async() => {
                const res = await props.unmarkFavourite(id)
                if(res.status === 'success'){
                    setSave(false)
                    props.successModal('Job has been umarked as Saved')
                }else{
                    setSave(false)
                    props.errorModal(res.message)
                }
            }, 0);
        }else{
            setSave(true)
            setTimeout(async() => {
                const res = await props.markFavourite(id)
                if(res.status === 'success'){
                    setSave(false)
                    props.successModal('Job has been marked as Saved')
                }else{
                    setSave(false)
                    props.errorModal(res.message)
                }
            }, 0);
        }
    }

    const editJob = (val) => {
        if(val === 'publish'){
            setSave(true)
            setTimeout(async() => {
                const res = await props.editJobStatus(id, val)
                if(res.status === 'success'){
                    setSave(false)
                    props.successModal('Job has been published successfully')
                }else{
                    setSave(false)
                    props.errorModal(res.message)
                }
            }, 0);
        }
        if(val === 'draft'){
            setSave(true)
            setTimeout(async() => {
                const res = await props.editJobStatus(id, val)
                if(res.status === 'success'){
                    setSave(false)
                    props.successModal('Job has been drafted successfully')
                }else{
                    setSave(false)
                    props.errorModal(res.message)
                }
            }, 0);
        }
        if(val === 'close'){
            setSave(true)
            setTimeout(async() => {
                const res = await props.editJobStatus(id, val)
                if(res.status === 'success'){
                    setSave(false)
                    props.successModal('Job has been closed successfully')
                }else{
                    setSave(false)
                    props.errorModal(res.message)
                }
            }, 0);
        }
        if(val === 'delete'){
            setSave(true)
            setTimeout(async() => {
                const res = await props.editJobStatus(id, val)
                if(res.status === 'success'){
                    setSave(false)
                    props.successModal('Job has been deleted successfully')
                }else{
                    setSave(false)
                    props.errorModal(res.message)
                }
            }, 0);
        }
        if(val === 'applications'){
            window.location.assign('/company/applications')
        }
    }

  return (
    <div>
        { props.modal && <Modal status={props.modal.status} />}
        <Card variant='outlined' elevation={0} className={classes.card} style={{marginTop: mt ? '1.5rem' : 0 }}>
        <CardContent style={{padding: '2rem'}}>
            <Box display={'flex'} gridGap='1rem' marginBottom={'1rem'}>
                <Avatar className={classes.large} >{title.charAt(0)}</Avatar>
                <span style={{width: '65%'}}>
                    <Typography variant='h6' noWrap>{title}</Typography>
                    <Typography color='textSecondary' >{company}</Typography>
                </span>
                {
                    props.currentUser && props.currentUser.isCompany && path === '/company/listing' ?
                    <IconButton onClick={onhandleClick} style={{padding: '.5rem', height: '100%', marginLeft: 'auto', background: grey[100]}}> <MoreVert /> </IconButton>
                    : null
                }
                {   props.currentUser && !props.currentUser.isCompany ?
                    <IconButton onClick={saveJob} style={{padding: '.5rem', height: '100%', marginLeft: 'auto', background: grey[100], color: fav && '#3156a2'}}> { fav ? <Favorite fontSize={'small'} /> : <FavoriteBorder fontSize={'small'} /> }</IconButton>
                    : null
                }
            </Box>

            <Typography color='textSecondary' paragraph className={classes.truncate}>
               {description}
            </Typography>

            <Box>
                <Chip className={classes.chips} label={<Typography color='textSecondary' variant='body2'>{type}</Typography>} variant='default'  />
                { level && <Chip className={classes.chips} label={<Typography color='textSecondary' variant='body2'>{level}</Typography>} variant='default'  /> }
                <Chip className={classes.chips} label={<Typography color='textSecondary' variant='body2'>{category}</Typography>} variant='default'  />
            </Box>

            <Divider style={{margin : '1rem 0'}} />
            { 
                path === '/company/applications' ? 
                <Button variant='contained' onClick={() => window.location.assign(`/company/applications/${slug}`)} disableElevation color='primary' style={{padding:'1rem 2rem',color: '#fff', height: '2.6rem', borderRadius: '10px'}}>{applications && applications.length} Applications</Button>
                :
                <Button variant='contained' onClick={() => window.location.assign(`/joblists/${slug}`)} disableElevation color='primary' style={{padding:'1rem 2rem',color: '#fff', height: '2.6rem', borderRadius: '10px'}}>{ path === 'applications' || 'listing' ? 'View Job' : 'Apply Now'}</Button>

            }

        </CardContent>
        </Card>

        {/* MORE OPTIONS */}
        <Popover open={open} onClose={handleClose} anchorEl={anchorEl} transformOrigin={{vertical: 'top', horizontal: 'right'}} anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}>
            <Card>
                <CardContent style={{padding: '.5rem'}}>
                    <MenuItem onClick={()=> editJob('edit')} ><Edit fontSize='small' className={classes.icon} /> <Typography>Edit Post</Typography> </MenuItem>
                    <MenuItem onClick={()=> editJob('applications')} ><PeopleAlt fontSize='small' className={classes.icon}  /> <Typography>Applications</Typography> </MenuItem>
                    { status === 'Published' ? null : <MenuItem onClick={()=> editJob('publish')} ><FileCopy fontSize='small' className={classes.icon} /> <Typography>Publish Post</Typography> </MenuItem> }
                    { status === 'Drafted' ? null : <MenuItem onClick={()=> editJob('draft')} ><FileCopy fontSize='small' className={classes.icon} /> <Typography>Draft Post</Typography> </MenuItem> }
                    { status === 'Closed' ? null : <MenuItem onClick={()=> editJob('close')} ><Cancel fontSize='small' className={classes.icon}  /> <Typography>Close Post</Typography> </MenuItem> }
                    <MenuItem onClick={()=> editJob('delete')} ><Delete fontSize='small' className={classes.icon}  /> <Typography>Delete Post</Typography> </MenuItem>
                </CardContent>
            </Card>
        </Popover>

        {/* LOADER */}
        <Dialog open={save} >
            <Box padding={'2.5rem'} textAlign={'center'}>
                {/* <img src={User} alt='update' width={'25%'}/> */}
                <Typography paragraph>Updating Job. Please wait...</Typography>
                <CircularProgress size={30} />
            </Box>
        </Dialog>
    </div>
  )
}


const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {unmarkFavourite, markFavourite, successModal, errorModal, editJobStatus, deleteJob})(JobItem)
import { combineReducers } from 'redux'


export const loaderReducer = ( loader = true, action) => {
    switch (action.type) {
        case "LOADING":
            return true
        case "EXIT_LOADING":
            return false
        default:
            return loader;
    }
}

export const userReducer = (user = null, action) => {
    switch (action.type) {
        case "NEW_USER":
            return {...action.payload}
        case "LOGIN_USER":
            return {...action.payload}
        case "NO_USER":
            return null 
        case "LOGOUT_USER":
            return null
        default:
           return user
    }
}

export const jobsReducer = (jobs = null, action) => {
    switch (action.type) {
        case "COMPANY_JOBS":
            return action.payload
        default:
           return jobs
    }
}

export const allJobsReducer = (allJobs = null, action) => {
    switch (action.type) {
        case "ALL_JOBS":
            return action.payload
        default:
           return allJobs
    }
}

export const notificationReducer = (notifications = null, action) => {
    switch (action.type) {
        case "USER_NOTIFICATIONS":
            return action.payload    
        default:
            return notifications
    }
}

export const modalReducer = (modal = null, action) => {
    switch (action.type) {
        case "LOADING_MODAL":
            return { message : action.payload, status: 'loading' }
        case "SUCCESS_MODAL":
            return { message : action.payload, status: 'success' }
        case "ERROR_MODAL":
            return { message : action.payload, status: 'error' }
        case "RESET_MODAL":
            return null
        default:
           return modal
    }
}

export default combineReducers({
    loader: loaderReducer,
    currentUser: userReducer,
    allJobs: allJobsReducer,
    jobs: jobsReducer,
    notifications: notificationReducer,
    modal: modalReducer
})


import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { errorModal, resetUserPassword } from "../actions/actions";
import { connect, useDispatch } from "react-redux";
import Modal from "../components/Modal";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "8rem",
    marginBottom: "8rem",
    "& .MuiDivider-root": {
      flexGrow: 1,
    },
  },
  wrapper: {
    [theme.breakpoints.up("sm")]: {
      margin: "2rem 0 4rem 0rem",
    },
    display: "flex",
    flexDirection: "column",
    padding: "3.2rem",
    borderRadius: "16px",
    boxShadow: "-24px 24px 72px 8px rgb(145 158 171 / 24%)",
    "& a": {
      color: theme.primaryColor,
      fontWeight: 400,
    },
  },
  wrap: {
    padding: "6rem 3rem",
    border: `1px solid ${grey[400]}`,
    textAlign: "center",
    borderRadius: "10px",
    transition: "all .3s ease",
    "&:hover": {
      border: `2px solid ${theme.secondaryColor}`,
      cursor: "pointer",
    },
  },
  title: {
    fontSize: "1.6rem",
    marginBottom: ".5rem",
    fontWeight: 500,
  },
  hold: {
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  field: {
    marginBottom: "1.5rem",
    borderRadius: "1.6rem",
    "& *": {
      borderRadius: "8px",
    },
    "& label.Mui-focused": {
      color: theme.primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.primaryColor}`,
      },
    },
  },
  fieldIcon: {
    color: grey[400],
    cursor: "pointer",
  },
  btn: {
    background: theme.primaryColor,
    padding: "1rem 0",
    color: "#fff",
    borderRadius: "8px",
    marginBottom: "2rem",
    "&:hover": {
      background: theme.secondaryColor,
    },
  },
}));

const ResetPassword = (props) => {
  const { resetUserPassword } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disable, setDisable] = useState(false);

  const onFormSubmit = async () => {
    if (code === "")
      return dispatch(errorModal("Invalid. Please provide reset code"));
    if (password === "" || confirmPassword === "")
      return dispatch(errorModal("Invalid. Please provide a password"));
    if (password !== confirmPassword)
      return dispatch(errorModal("Invalid. Passwords do not match"));
    const data = {
      code,
      password,
    };
    try {
      await resetUserPassword(data);
    } catch (error) {
      console.log(error?.response);
      setDisable(false);
    }
  };

  return (
    <Box>
      {/* MODAL  */}
      {props.modal && <Modal status={props.modal.status} />}
      <Container maxWidth={"sm"} className={classes.root}>
        <div className={`${classes.wrapper}`}>
          <Typography className={classes.title} variant="h5">
            Reset Password
          </Typography>
          <Typography
            style={{ marginBottom: "2.5rem" }}
            paragraph
            color="textSecondary"
          >
            Please provide the code sent to your email to reset a new password
          </Typography>
          <TextField
            className={classes.field}
            variant="outlined"
            label="Reset Code"
            helperText=""
            value={code}
            onChange={(e) => setCode(e.target.value)}
            fullWidth
          />
          <TextField
            className={classes.field}
            variant="outlined"
            label="Password"
            helperText=""
            fullWidth
            type={"password"}
            value={password}
            onChange={(e) => setPassword(e.target?.value)}
          />
          <TextField
            className={classes.field}
            variant="outlined"
            label="Confirm Password"
            helperText=""
            fullWidth
            type={"password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target?.value)}
          />

          <Button
            className={classes.btn}
            disabled={disable}
            onClick={onFormSubmit}
            variant="contained"
            disableElevation
            type="submit"
            fullWidth
          >
            Reset Password
          </Button>
        </div>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { resetUserPassword })(ResetPassword);

import { Box, Divider, IconButton, MenuItem, Popover, Typography } from '@material-ui/core'
import { Delete, MoreVert, Visibility } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import PDF from '../assets/pdf.png'


const useStyles = makeStyles(theme => ({
    avatar: {
        width: theme.spacing(6),
        height: theme.spacing(6)
    }
}))

const ResumeItem = (props) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    }
    const onhandleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }


  return (
    <div>
        <Box display='flex' gridGap={'1rem'} alignItems='center' margin='15px 0 20px 0'>
            <img src={PDF} alt='pdf-resume' width='8%' />
            <Box>
                <Typography variant='h6' className={classes.title}>{props.name}</Typography>
                <span style={{display: 'flex', gap:'1rem', alignItems:'center'}}>
                    <Typography color='textPrimary'>{Math.floor(props.size)} KB</Typography> - 
                    <Typography variant='body2' color='textSecondary'>{props.date}</Typography>
                </span>
            </Box>
            <IconButton style={{marginLeft: 'auto', display: 'block'}} onClick={onhandleClick}>
                <MoreVert />
            </IconButton>
        </Box>
        <Divider />

        <Popover open={open} onClose={handleClose} anchorEl={anchorEl} transformOrigin={{vertical: 'top', horizontal: 'right'}} anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}} >
           <Box padding='.5rem'>
                <MenuItem >
                    <Visibility fontSize='small' style={{color: 'grey', marginRight: '5px'}} />
                    <Typography>View</Typography>
                </MenuItem>
                <MenuItem onClick={()=> {}}>
                    <Delete fontSize='small' style={{color: 'grey', marginRight: '5px'}} />
                    <Typography>Delete</Typography>
                </MenuItem>
           </Box>
        </Popover>
    </div>
  )
}

export default ResumeItem
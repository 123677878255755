import { Avatar, Box, Button, Card, CardContent, Chip, Container, Dialog, Grid, IconButton, LinearProgress, TextField, Typography } from '@material-ui/core'
import { CameraAlt} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useReducer, useRef, useState } from 'react'
import { connect } from 'react-redux'
import Cover from '../../assets/cover.jpg'
import Payment from '../../assets/payment.png'
import PricingPlan from '../../components/PricingPlan'
import CheckList from '../../assets/checklist.gif'
import SaveBox from '../../components/SaveBox'
import { errorModal, successModal, paymentPlan } from '../../actions/actions'

const useStyles = makeStyles(theme => ({
  root:  {
    paddingTop: '3rem'

  },
  cover : {
    height: '10rem',
    borderRadius: '15px',
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: '0 auto',
    marginBottom: '.5rem',
    border: '6px solid #fff'
  },
  title : {
    marginTop: '2rem',
    marginBottom: '-1rem',
    fontWeight: 500
  },
  title2: {
    fontSize: '1.5rem',
    fontWeight: 800,
  },
  card : {
    marginTop: '2rem',
    padding: '1rem',
    borderRadius: '15px',
    transition: 'all .2s ease',
    '&:hover': {
        boxShadow: '0 2px 1rem rgb(0 0 0 / 9%)',
        border: `1px solid ${theme.secondaryColor}`
      }
  },
  field : {
    marginBottom: '1.5rem'
  },
  bar: {
    height: '1rem',
    borderRadius:'10px',
    marginTop: '1rem',
    marginBottom: '5px'
  }
}))

const Settings = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState({open: false, text: ''})
  const cover = useRef()

  const infoState = {
    firstname: '',
    lastname: '',
    phone: '',
    industry: '',
    website: ''
  }

  const passState = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const infoReducer = (state, action) => {
    switch (action.type) {
      case "FNAME":
        return {...state, firstname: action.payload}
      case "LNAME":
        return {...state, lastname: action.payload}
      case "PHONE":
        return {...state, phone: action.payload}
      case "INDUSTRY":
        return {...state, industry: action.payload}
      case "WEBSITE":
        return {...state, website: action.payload}
      case "RESET":
        return infoState
      default:
        return state
    }
  }

  const passReducer = (state, action) => {
    switch (action.type) {
      case "CURRENT":
        return {...state, currentPassword: action.payload}
      case "NEW":
        return {...state, newPassword: action.payload}
      case "CONFIRM":
        return {...state, confirmPassword: action.payload}
      case "RESET":
        return passState
      default:
        return state
    }
  }

  const [infoInput, infoDispatch] = useReducer(infoReducer, infoState)
  const [passInput, passDispatch] = useReducer(passReducer, passState)


  const onFormSubmit =() => {
    
  }

  const uploadCoverImage = () => {

  }

  const calcPercent = (a, b) => {
    const total = a + b
    const res = (b / total) * 100
    return 100 - res
  }


  return (
    <div>
      <Container>
        <Grid container spacing={4}>
          <Grid item sm={4}>
              <Card variant='outlined' elevation={0} className={classes.card}>
                <Box className={classes.cover} style={{backgroundImage: props.currentUser.photo && props.currentUser.photo.coverImage ? `url(${props.currentUser.photo.coverImage})` : `url(${Cover})`}}>
                       <input type={'file'} accept='.jpg, .png, .jpeg' ref={cover} style={{display: 'none'}} onChange={(e)=> uploadCoverImage(e.target.files[0])} />
                      <IconButton style={{background: '#00000080', color: '#fff', marginTop: '.5rem', marginLeft: '.5rem'}} onClick={()=> cover.current.click()}><CameraAlt /></IconButton>
                </Box>
                <CardContent >
                    <Box textAlign={'center'} marginTop='-3.5rem'>
                      <Avatar className={classes.avatar} src={props.currentUser.photo && props.currentUser.photo.profile ? `${props.currentUser.photo.profile}` : null} />
                      <span>
                        <Typography variant='h6'>{props.currentUser.companyName}</Typography>
                        <Typography color='textSecondary'>{props.currentUser.email}</Typography>
                        <Typography gutterBottom color='textSecondary'>(+233) {props.currentUser.phone}</Typography>
                        <Button color='primary' style={{color: '#fff'}} variant='contained' disableElevation>Upload Logo</Button>
                      </span>
                  </Box>
                </CardContent>
              </Card>

              <Card variant='outlined' elevation={0} className={classes.card}>
                {
                  props.currentUser.plan ?
                    <CardContent >
                        <Box display={'flex'} justifyContent='space-between' alignItems={'flex-end'}>
                          <span>
                            <Typography variant='h6'>{props.currentUser.plan.name} plan</Typography>
                            <Typography color='textSecondary'>Idea plan for small team</Typography>
                          </span>
                          <Chip label={`GHc ${props.currentUser.plan.price}`} />
                        </Box>
                        <LinearProgress variant='determinate' value={props.currentUser.plan ? calcPercent(props.currentUser.plan.credit, props.currentUser.plan.used) : 0} className={classes.bar} color='primary' />
                        <Typography variant='body2' color='textSecondary'>({calcPercent(props.currentUser.plan.credit, props.currentUser.plan.used)}%) {props.currentUser.plan.used} out of {props.currentUser.plan.used + props.currentUser.plan.credit} Job Credits </Typography>
                        <Button color='secondary' style={{color: '#fff', marginTop: '1rem'}} variant='contained' onClick={() => setOpen(true)} disableElevation>Upgrade Plan</Button>

                    </CardContent>
                  :
                  <CardContent style={{textAlign: 'center'}}>
                    <img src={Payment} alt='no-plan' width='35%' style={{marginBottom: '1rem'}} />
                    <Typography variant='h6'>No Billing Plan</Typography>
                    <Typography color='textSecondary'>Manage your billing plans here</Typography>
                    <Button color='primary' style={{color: '#fff', marginTop: '1rem'}} variant='contained' onClick={() => setOpen(true)} disableElevation>Choose A Plan</Button>

                  </CardContent>
                }
                


              </Card>
          </Grid>

          <Grid item sm={8}>
            <Box>
              <Typography variant='h5' className={classes.title}>Personal Info.</Typography>
              <Card variant='outlined' elevation={0} className={classes.card}>
                  <CardContent>
                    <TextField className={classes.field} fullWidth variant='outlined' placeholder={props.currentUser && props.currentUser.email} disabled />
                    <TextField className={classes.field} fullWidth variant='outlined' label='Firstname' placeholder={props.currentUser && props.currentUser.firstname} value={infoInput.firstname} onChange={(e) => infoDispatch({type: 'FNAME', payload: e.target.value})} />
                    <TextField className={classes.field} fullWidth variant='outlined' label='Lastname' placeholder={props.currentUser && props.currentUser.lastname} value={infoInput.lastname} onChange={(e) => infoDispatch({type: 'LNAME', payload: e.target.value})} />
                    <TextField className={classes.field} type={'number'} inputProps={{min: 0}} fullWidth variant='outlined' label='Phone' placeholder={props.currentUser && props.currentUser.phone} value={infoInput.phone} onChange={(e) => infoDispatch({type: 'PHONE', payload: e.target.value})}/>
                    <TextField className={classes.field} fullWidth variant='outlined' label='Website' placeholder={props.currentUser && props.currentUser.website} value={infoInput.website} onChange={(e) => infoDispatch({type: 'WEBSITE', payload: e.target.value})} />
                    <Button variant='contained' disableElevation color='primary' onClick={() => onFormSubmit('personal')}>Update Details</Button>
                  </CardContent>
              </Card>
            </Box>

            <Box>
              <Typography variant='h5' className={classes.title}>Password & Security</Typography>
              <Card variant='outlined' elevation={0} className={classes.card}>
                  <CardContent>
                    <TextField className={classes.field} type={'password'} fullWidth variant='outlined' label='Current Password' value={passInput.currentPassword} onChange={(e) => passDispatch({type: 'CURRENT', payload: e.target.value})}  />
                    <TextField className={classes.field} type={'password'} fullWidth variant='outlined' label='New Password' value={passInput.newPassword} onChange={(e) => passDispatch({type: 'NEW', payload: e.target.value})}  />
                    <TextField className={classes.field} type={'password'} fullWidth variant='outlined' label='Confirm Password' value={passInput.confirmPassword} onChange={(e) => passDispatch({type: 'CONFIRM', payload: e.target.value})}  />
                    
                    <Button variant='contained' disableElevation color='primary' onClick={() => onFormSubmit('password')}>Update Password</Button>
                  </CardContent>
              </Card>
            </Box>


          </Grid>
        </Grid>
      </Container>


      <Dialog open={open} maxWidth='lg' fullWidth onClose={()=> setOpen(false)}>
            <Box padding='5rem' textAlign={'center'} width='auto' >
                <Typography style={{fontWeight: 600}} variant='h5'>Pricing Plan</Typography>
                <Typography variant='body1' color='textSecondary' style={{marginBottom: '2rem'}}>Choose the plan tailored to your needs</Typography>

                    <Box>
                        <PricingPlan />
                    </Box>

            </Box>

        </Dialog>

        {/* LOADER */}
        <SaveBox open={loader.open} text={loader.text} image={CheckList} />

    </div>
  )
}



const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps,{ errorModal, successModal, paymentPlan })(Settings)
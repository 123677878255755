import { Box, Button, Container, Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { connect } from 'react-redux'
import PricingPlan from '../components/PricingPlan'
import { errorModal, successModal, paymentPlan } from '../actions/actions'
import Modal from '../components/Modal'
import Testimonial from '../components/Testimonial'
import Pic from '../assets/pic2.png'


const useStyles = makeStyles(theme => ({
    root : {

    },
    title: {
        fontWeight: 600,
        color: '#fff'
    },
    title2: {
        fontSize: '1.5rem',
        fontWeight: 800,
      },
    wrap: {
        background: theme.primaryColor,
        padding: '7rem 0',
        position: 'relative',
        zIndex: 0
    },
    field: {
        marginBottom: '1rem'
    },
    img: {
        position: 'absolute', 
        bottom: '-4%', 
        right: '5%',
        width: '45%',
        [theme.breakpoints.down('md')]: {
            width: '60%'
        }
    }
}))


const Pricing = (props) => {
    const classes = useStyles()

  return (
    <div>
        {
            props.modal && <Modal status={props.modal.status} />
        }
            <Box className={classes.wrap}>
                <Container>
                    <Hidden mdUp>
                        <Box color='#fff' paddingBottom={'4rem'} textAlign='center'>
                                <Typography style={{letterSpacing: '.3rem'}} >PLAN & PRICING</Typography>
                                <Typography variant='h4' className={classes.title}>Choose the plan that's right for you</Typography>
                                <Typography style={{marginBottom: '1rem'}}>Helping you grow. All plans are tailored just to help you grow</Typography>
                                <Button variant='contained' size='large' color='secondary' style={{marginTop: '1rem'}} disableElevation>Get Started</Button>
                            </Box>
                    </Hidden>
                    <Hidden smDown>
                    <Grid container spacing={3}>
                        <Grid item sm={12} lg={6} md={7}>
                            <Box color='#fff' padding={'2rem'}>
                                <Typography style={{letterSpacing: '.3rem'}} >PLAN & PRICING</Typography>
                                <Typography variant='h3' className={classes.title}>Choose the plan that's right for you</Typography>
                                
                                <Button variant='contained' size='large' color='secondary' style={{marginTop: '1rem'}} disableElevation>Get Started</Button>
                            </Box>
                        </Grid>
                        
                            <Grid item sm={12} md={5} lg={6} >
                                <img src={Pic} className={classes.img} alt='pricing'/>
                            </Grid>
                    </Grid>
                    </Hidden>
                </Container>

            </Box>
            <Box marginTop={'-6rem'} position='relative' zIndex={'2'}>
                <Container >
                    <PricingPlan />

                    <Box margin= '6rem 0 8rem 0'>
                        <Box textAlign={'center'} marginBottom='2rem'>
                            <Typography color='textSecondary' gutterBottom style={{letterSpacing: '.5rem'}} >TESTIMONIALS</Typography>
                            <Typography variant='h4' color='textPrimary' style={{fontWeight: 600}}>What Our Clients Say</Typography>
                        </Box>
                        
                        <Grid container spacing={3}>
                                {
                                    [1,2,3,4,5,6].map((el,index) => {
                                        return (
                                            <Grid key={index} item sm={12} md={4} lg={4}>
                                                <Testimonial />
                                            </Grid>
                                        )
                                    })
                                }
                        </Grid>

                    </Box>
                </Container>
            </Box>


    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {errorModal, successModal, paymentPlan})(Pricing)
import { alpha, Box, Button, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'



const useStyles = makeStyles(theme => ({
    title : {
        fontWeight: 600,
        fontSize: '1.2rem'
    },
    wrap : {
        borderRadius: '10px',
        background: alpha('#faa831', 0.4),
        '& *' : {
            borderRadius: '10px'
        }
    },
    fixed : {
        position: 'sticky',
        top: '2rem'
    }
}))

const JobAlert = ({fix}) => {
    const classes = useStyles()

  return (
        <div className={`${classes.wrap} ${fix ? classes.fixed : null}`}>
            <Box padding={'2rem'}>
                <Typography className={classes.title}>Internship Alert</Typography>
                <Typography color='textSecondary' paragraph>Create an internship alert now and never miss an openning</Typography>
                <TextField fullWidth variant='outlined' placeholder='Enter email address' style={{background: '#fff'}} />
                <Button fullWidth variant='contained' disableElevation color='primary' style={{color: '#fff', height: '3rem', marginTop: '1rem', textTransform: 'none'}}>Create Internship Alert</Button>
            </Box>
        </div>
  )
}

export default JobAlert
import { Avatar, Box, Divider, IconButton, MenuItem, Popover, Typography } from '@material-ui/core'
import { Delete, Edit, MoreVert } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
    avatar: {
        width: theme.spacing(6),
        height: theme.spacing(6)
    }
}))

const ExperienceItem = (props) => {
    const {company, position, startYear, endYear} = props
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    }
    const onhandleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

  return (
      <>
        <Box display='flex' gridGap={'1rem'} alignItems='center' margin='15px 0 20px 0'>
            <Avatar className={classes.avatar}>{company.charAt(0).toUpperCase()}</Avatar>
            <Box>
                <Typography variant='h6' className={classes.title}>{position}</Typography>
                <span style={{display: 'flex', gap:'1rem', alignItems:'center'}}>
                    <Typography color='textPrimary'>{company}</Typography>
                    <Typography variant='body2' color='textSecondary'>{startYear} - {endYear}</Typography>
                </span>
            </Box>
            <IconButton style={{marginLeft: 'auto', display: 'block'}} onClick={onhandleClick}>
                <MoreVert />
            </IconButton>
        </Box>
        <Divider />

        <Popover open={open} onClose={handleClose} anchorEl={anchorEl} transformOrigin={{vertical: 'top', horizontal: 'right'}} anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}} >
        <Box padding='.5rem'>
            <MenuItem>
                <Edit fontSize='small' style={{color: 'grey', marginRight: '5px'}} />
                <Typography>Edit</Typography>
            </MenuItem>
            <MenuItem onClick={()=>props.delete()}>
                <Delete fontSize='small' style={{color: 'grey', marginRight: '5px'}} />
                <Typography>Delete</Typography>
            </MenuItem>
        </Box>
        </Popover>
      </>
  )
}

export default ExperienceItem
import { Box,  Container, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Search, ViewList, ViewModule } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { connect } from 'react-redux'
import Listing from '../../assets/listing.png'
import JobItem from '../../components/JobItem'
import Loader from '../../components/Loader'



const useStyles = makeStyles(theme => ({
    root : {
        padding: '3rem 0',
        height: '100vh'
    }
}))
const Favourites = (props) => {
    const { currentUser } = props
    const classes = useStyles()
    const[list, setList] = React.useState(false)


    const renderPage = () => {
        if(currentUser && currentUser.favourites){
            if(currentUser.favourites.length > 0){
                return (
                    <Grid container spacing={3}>
                        {
                            currentUser.favourites.map((el) => {
                                return (
                                    <Grid key={el.id} item lg={!list ? 4 : 12}>
                                        <JobItem  
                                        id={el.job.id}
                                        title={el.job.title} 
                                        company={el.job.company.companyName} 
                                        description={el.job.description}
                                        category={el.job.industry} 
                                        level={ list && `${el.job.experience} Years` }
                                        type={el.job.jobType}
                                        mt='1rem'
                                        slug={el.job.slug}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            }else{
                return(
                    
                    <Box padding={'6rem'} bgcolor={'#fff'} border='1px dashed lightgrey' borderRadius={'10px'} marginTop={'3rem'}>
                        <Box textAlign={'center'} marginBottom='1rem'>
                                <img src={Listing} alt='job-listing' width='20%' style={{margin:'0 auto', marginBottom: '2rem'}} />
                                <Typography variant='h5' gutterBottom>View Your Saved Jobs</Typography>
                                <Typography color='textSecondary'>You have no saved jobs. Add jobs as favourites to find them here</Typography>
                            </Box>
                    </Box>
                )

            }
        }

        return <Loader />

    }

  return (
    <div className={classes.root}>
        <Container>
            <Box display={'flex'}>
                <Typography variant='h6'>Saved Jobs ({currentUser ? currentUser.favourites.length : 0})</Typography>
                <span style={{marginLeft: 'auto', display: 'flex', }}>

                <IconButton style={{padding: '.5rem'}} onClick={()=> {setList(true)}}><ViewList fontSize='large' /></IconButton>
                <IconButton style={{padding: '.5rem'}} onClick={()=> {setList(false)}}><ViewModule fontSize='large' /></IconButton>
                </span>
            </Box>

            {/* Searchbar */}
            <TextField className={classes.search} InputProps={{
                startAdornment: <InputAdornment position='start'><Search style={{color: grey[400]}} /></InputAdornment>
            }} size='medium' variant='outlined' fullWidth placeholder='Search for interships' />

            { 
               renderPage()
            }

        </Container>
    </div>
  )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, {})(Favourites)
import { Box, Checkbox, FormControlLabel, IconButton, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/styles'
import React from 'react'


const useStyles = makeStyles(theme => ({
    filter: {
        margin: '.3rem 0',
        '& span' :{
          display: 'flex',
          justifyContent: 'space-between'
        }
      },
      iconBtn: {
        height: '100%',
        padding: '.5rem',
        background: grey[200]
      }
}))
const CheckBoxFilter = ({title, number}) => {
    const classes = useStyles()



  return (
        <Box className={classes.filter}>
            <span>
                <FormControlLabel label={<Typography color='textSecondary'>{title}</Typography>} control={<Checkbox color='primary'/>}/>
                <IconButton className={classes.iconBtn}><Typography variant='body2'>{number}</Typography></IconButton>
            </span>
        </Box>
  )
}

export default CheckBoxFilter